import React from 'react'
import DefaultLogo from '../../assests/images/DefaultLogo.png'

const NotFound = () => {
    return (
        <div className='main-bg not-found-sec'>
            <div className='not-found-sec-logo'>
            <img src={DefaultLogo} alt="Logo" />
            </div>
            <div className='not-found-sec-content'>
                <h1 className='content404'>404</h1>
                <h3 className='content-oops'>OOPS! We Can't find that page</h3>
            </div>
        </div>
    )
}

export default NotFound
