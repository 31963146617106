// Global Styles

import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import Home from "./Components/Home/Home";
import NotFound from "./Components/NotFound/NotFound";


import DynamicRegister from "./Components/DynamicRegister/DynamicRegister"
import Forgot from "./Components/ForgotPassword/Forgot";
import Reset from "./Components/ResetPassword/Reset";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Changepassword from "./Components/ChangePassword/Changepassword";
import DynamicUpdateProfile from './Components/DynamicUpdateProfile/DynamicUpdateProfile'
import Health from "./Components/Health/Health";

import { LoginCallback,  } from "@okta/okta-react";
import Form1 from "./Components/Form/Form1";
import SecurityQuestion from "./Components/MultiFactor/SecuritySetUp/SecurityQuestion";
import SecurityQuestionOther from "./Components/MultiFactor/OtherFactors/Authentication/SecurityQuestionOther";
import EmailSetUp from "./Components/MultiFactor/EmailSetUp/EmailSetUp";
import EmailOther from "./Components/MultiFactor/OtherFactors/Authentication/EmailOther";
import ActivateUserPassword from "./Components/ActivateUserPassword/ActivateUserPassword";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import PasscodeRequired from "./Components/MultiFactor/OktaVerify/PasscodeRequired";
import {useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

const Smsverify = lazy(() =>
  import("./Components/MultiFactor/SmsAuth/Smsverify")
);
const Multifactor = lazy(() =>
  import("./Components/MultiFactor/OktaVerify/Multifactor")
);
const Passcode = lazy(() =>
  import("./Components/MultiFactor/OktaVerify/Passcode")
);
const Voiceverify = lazy(() =>
  import("./Components/MultiFactor/VoiceAuth/Voiceverify")
);
const OtherFactors = lazy(() =>
  import("./Components/MultiFactor/OtherFactors/OtherFactors")
);
const Oktaverify = lazy(() =>
  import("./Components/MultiFactor/OtherFactors/Authentication/Oktaverify")
);
const AuthSms = lazy(() =>
  import("./Components/MultiFactor/OtherFactors/Authentication/AuthSms")
);
const VoiceAuth = lazy(() =>
  import("./Components/MultiFactor/OtherFactors/Authentication/VoiceAuth")
);
const OktaSetup = lazy(() =>
  import("./Components/MultiFactor/OktaSetUp/OktaSetup")
);
const OtherFactorsRequired = lazy( () => 
 import("./Components/MultiFactor/OktaSetUp/OtherFactorsRequired")
)

// //****** LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: 'red',
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: '100%',
    },
  })
);


function App() {
  const classes = useStyles();
  const storeData = useSelector((state) => state);
  const tokenFlag = storeData?.LanguageData?.tokenFlag
 

  return (
    <>
    {!tokenFlag ? (
        <Backdrop className={classes.root}  sx={{ color: '#fff' }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) :
    <BrowserRouter>
     <ErrorBoundary>
      <ToastContainer />
        <Switch>
          <Route exact path="/login">
            <Form1 />
          </Route>
          <Route exact path="/" component={Home} />
          <Route exact path="/create-account/:id">
          <DynamicRegister />
          </Route>
          <Route exact path="/setpassword/:id/:user_id">
            <ActivateUserPassword />
          </Route>
          <Route exact path="/forgotpassword/:id">
            <Forgot />
          </Route>
          <Route exact path="/resetpassword/:id/:recoveryToken">
            <Reset />
          </Route>
          <Route exact path="/changepassword/:id/:user_id">
            <Changepassword />
          </Route>
          <Route exact path='/update-profile/:id/:user_id'>
           <DynamicUpdateProfile />
          </Route>
          <Route exact path="/health">
            <Health />
          </Route>
          
          <Suspense fallback={ <div>Loading...</div>}>
            <Switch>
            <Route exact path="/multifactor/:userId/:id">
              <Multifactor />
            </Route>
            <Route exact path="/passcode/:userId/:id">
              <Passcode />
            </Route>
            <Route exact path="/passcoderequire/:userId/:id">
              <PasscodeRequired />
            </Route>
            <Route exact path="/oktaVerify/:userId/:id">
              <OktaSetup />
            </Route>
            <Route exact path="/otherfactorsrequire/:userId/:id">
              <OtherFactorsRequired />
            </Route>
            <Route exact path="/smsauth/:userId/:id">
              <Smsverify />
            </Route>
            <Route exact path="/voiceauth/:userId/:id">
              <Voiceverify />
            </Route>
            <Route exact path="/securityauth/:userId/:id">
              <SecurityQuestion />
            </Route>
            <Route exact path="/emailauth/:userId/:id">
              <EmailSetUp />
            </Route>
            <Route exact path="/otherfactors/:userId/:id">
              <OtherFactors />
            </Route>
            <Route exact path="/otherfactors/oktaVerify/:userId/:id">
              <Oktaverify />
            </Route>
            <Route exact path="/otherfactors/sms/:userId/:id">
              <AuthSms />
            </Route>
            <Route exact path="/otherfactors/voice/:userId/:id">
              <VoiceAuth />
            </Route>
            <Route exact path="/otherfactors/security/:userId/:id">
             <SecurityQuestionOther />
            </Route> 
            <Route exact path="/otherfactors/email/:userId/:id">
              <EmailOther />
            </Route>
            <Route path="*" component={NotFound} >
            </Route>
          </Switch>
          </Suspense>
          <Route path="/login/callback" component={LoginCallback}></Route>
        </Switch>
    </ErrorBoundary>
    </BrowserRouter>
    }
    </>
  );
}

export default App;
