import axios from 'axios';
import { Base64 } from 'js-base64';
import React, { Component } from 'react'
import DefaultLogo from '../../assests/images/DefaultLogo.png'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, Error: '' };
  }
  static getDerivedStateFromError(error) {
    // It will update the state so the next render shows the fallback UI.
    return { hasError: true, Error: error };
  }

  componentDidUpdate() {
    const { REACT_APP_API_ENDPOINT } = process.env;
    const LoggingErrors = async () => {
      if(this.state.Error.stack && this.state.Error.message){
        axios
        .post(`${REACT_APP_API_ENDPOINT}ui-error`, {
          stack: Base64.encode(this.state.Error.stack),
          message: Base64.encode(this.state.Error.message)
        })
        .then((res) => res)
        .catch((err) => {
          console.log({ err });
        });
      }
    };
    LoggingErrors();
  }

  render() {
    if (this.state.hasError) {
      return <>
          <div className='main-bg not-found-sec'>
            <div className='not-found-sec-logo'>
            <img src={DefaultLogo} alt="Logo" />
            </div>
            <div className='not-found-sec-content'>
                <h1 className='content404'>Oops !</h1>
                <h3 className='content-oops'>Please try Again !!!</h3>
            </div>
        </div>
       </>
    }
    return this.props.children;
  }
}
