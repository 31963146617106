import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import DefaultLogo from "../../../../assests/images/DefaultLogo.png";
import DefaultBack from "../../../../assests/images/background-img.png";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../../../Redux/Actions/fetch_language";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import LanguageChange from "../../../LanguageChange/LanguageChange";
import sessionRoute from "../../../SessionRoute/sessionRoute";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

//**** LOADER ******** */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      position: "absolute",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);


function EmailOther() {

  //******* INTIALIZE VARIABELS  ********** */

  const classes = useStyles();
  const [intialLoader, setIntialLoader] = useState(false);
  const history = useHistory()
  // const dispatch = useDispatch()
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [data4, setData4] = useState({});
  const [Email, setEmail] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  //*****  FETCHING DATA FROM QUERY PARAMETERS */

  const Data = useParams()
  const  id  = DOMPurify.sanitize(Data.id);
  const userId = DOMPurify.sanitize(Data.userId)
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let stateToken = DOMPurify.sanitize(query.get("stateToken"));
  const Relay = DOMPurify.sanitize(query.get("Relay"));
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"))

  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = "lbl_other_email_setup";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (defaultLang === "default" && LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0) {
    langData = LangData.LanguageData.LanguageData.filter((item) => item.code === "default")[0]?.data;
  } else {
    langData = LangData.LanguageData.LanguageData.length > 0 ? LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data: {};
  }

  // useEffect(() => {
  //    dispatch(fetchLanguageData(LanguageCode, id, PageName))
  //    //eslint-disable-next-line
  // }, [])

  

  //** GETTING THE USER EMAIL  */

  async function Factors() {
    let reCaptcha_token =''
    reCaptcha_token = await executeRecaptcha("");
    await axios.get(`${REACT_APP_API_ENDPOINT}/get-user-detail/${userId}/${id}?reCaptchaToken=${reCaptcha_token}`)
   .then( res => {
       setEmail(res.data?.profile?.email)
   }).catch( err => {
    if(err?.response?.data?.errorCode === "invalid-captcha"){
      toast.error('Invalid Captcha',{position:'top-center'});
    }
   })
  }

  useEffect(() =>{
    Factors()
    //eslint-disable-next-line
  },[])

  //******* ACTIVATE USER EMAIL */

 const ActivateEmail = async() => {
     await axios.post(`${REACT_APP_API_ENDPOINT}factors/user-enroll-email-factor/${userId}`,{
        factorType :"email",
        provider: "OKTA",
        email:Email
     }).then( res =>{
        if (res.data.status === "ACTIVE") {
            history.push(
              `/passcode/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&activate=false`
            );
        }
     }).catch( err =>{
      if(err?.response?.data?.errorCode === 'invalid_session' && err?.response?.status === 401){
        toast.error(langData?.lbl_other_email_setup_session_expired, {
          position: 'top-center',
        })
      }else{
        toast.error(err?.response?.data?.errorSummary, {
          position: 'top-center',
        });
      }
     })
 }

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

   //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

   const Header_Color = data4.custom_properties?.headerColor 
   const SecondaryButtonColor= data4.custom_properties?.secondaryButtonColor
   const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
   const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  //***** BACK TO FACTORS ******** */

  const BacktoFactors = () => {
    history.push(`/otherfactors/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}`);
  };

  
  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          
          <div className="body" style={{fontFamily: fontFamily}} >
          <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'} />
          <div className="main-bg configure-sec" style={{backgroundImage: `url(${BackgroundImage})`}}>
        <div className="white-wrapper">
            <div className="text-center">
                <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" onClick={() => sessionRoute(data4?.appBasicDetails?.appRedirectionURL)} />
                </div>
                <h2 className="snacks-title" style={{fontFamily: fontFamily, color:Header_Color}} >{langData.lbl_other_email_setup}</h2>                
            </div>
            <div className="wrapper-inner">
                        <div className="form-group">
                              <button className="btn btn-yellow btn-block" onClick={ActivateEmail} style={{background: SignIn_button_backgroundColor}}>{langData.lbl_other_email_setup_activate_mail}</button>
                        </div>
                        <div className="form-group">
                        <button className="btn btn-yellow-brd btn-block" type="button" onClick ={BacktoFactors} style={{background: SecondaryButtonColor}}>{langData.lbl_other_email_setup_back_factor}</button>
                    </div>
                        
                    <div className="form-group text-center mb-0">
                        <p  className="create-account text-decoration-none btn-text">{langData.lbl_other_email_setup_safe_secure}</p>
                     </div>
            </div>
        </div>
    </div>
    </div>
        </>
      )}
    </div>
  );
}

export default EmailOther;
