import { ActionTypes } from "../Constants/ActionTypes";

const DefaultLanguage = {
          "lbl_mfa_other_factor_setup": "SETUP",
          "lbl_update_profile_subscription": "Yes, sign me up to receive emails from Pepsi Mic Drop, PepsiCo, and its brands.",
          "lbl_mfa_verify_security_submit": "Submit",
          "lbl_login_invalid_email": "Email Must Be Valid",
          "lbl_update_profile_address": "Address",
          "lbl_update_profile_address_required": "Address Field is Required",
          "lbl_create_account_street_address": "Street Address",
          "lbl_create_account_lastName": "Last name",
          "lbl_other_security_question_setup_back_factor": "Back to Factors",
          "lbl_reset_password": "Reset Password",
          "lbl_email_setup_safe_secure": "Keeping PepsiCo safe and secure",
          "lbl_create_account_firstName": "First name",
          "lbl_voice_setup_call_verify": "Verify",
          "lbl_create_account_website": "Website",
          "lbl_update_profile_preferredLanguage": "Preferred language",
          "lbl_other_factor_sms_safe_and_secure": "Keeping PepsiCo safe and secure",
          "lbl_activate_password_password_invalid": "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
          "lbl_voice_setup_call_code": "Enter Code",
          "lbl_mfa_verify_email_code": "EmailCode",
          "lbl_update_profile_title": "Title",
          "lbl_change_password_temrs_conditions": "Terms & Conditions",
          "lbl_update_profile_locality": "Locality",
          "lbl_update_profile_given_name": "Given Name",
          "lbl_update_profile_maximum_argument": "Maximum {0} characters are allowed ",
          "lbl_change_password_one_uppercase_required": "Must contain at least one upper case letter",
          "lbl_create_account_one_lowercase_required": "Must contain at least one lower case letter",
          "lbl_sms_setup_please_select_country": "Please select Country",
          "lbl_create_account_honorificSuffix": "Honorific suffix",
          "lbl_login_other_issues": "Other Issues",
          "lbl_activate_password_cancel": "Cancel",
          "lbl_activate_password_reactivate_account": "Reactivate Account",
          "lbl_activate_password_lower_char": "include lower case characters",
          "lbl_activate_password_requirements": "Password Requirements",
          "lbl_create_account_email_invalid": "Email must be Valid",
          "lbl_create_account_privacy_policy": "Privacy Policy",
          "lbl_update_profile_nickname": "Nickname",
          "lbl_change_password_no_username": "should not include part of Email",
          "lbl_update_profile_middleName": "Middle name",
          "lbl_create_account_phone_number": "Phone Number",
          "lbl_create_account_postalAddress": "Postal Address",
          "lbl_okta_verify_push_notification": "SETUP PUSH NOTIFICATION",
          "lbl_other_security_question_setup_select_one": "Please Select Security Question",
          "lbl_activate_password_new_password": "New Password",
          "lbl_create_account_gender": "Gender",
          "lbl_update_profile_userAddress": "Address",
          "lbl_create_account_displayName": "Display name",
          "lbl_create_account_userName": "Username",
          "lbl_activate_password_no_first_name": "should not include part of First Name",
          "lbl_update_profile_first_name_required": "First Name is Required",
          "lbl_update_profile_wallet_address": "Full Ethereum Wallet Address (0x374D49…)",
          "lbl_mfa_verify_input_code": "Code",
          "lbl_login_account_lock": "Account Is Locked, Please Contact Administrator",
          "lbl_create_account_costCenter": "Cost center",
          "lbl_create_account_managerId": "ManagerId",
          "lbl_change_password_new_password_required": "Please enter new password",
          "lbl_reset_password_new_password": "New Password",
          "lbl_create_account_profile": "Profile URL",
          "lbl_login_sign_in": "Sign In",
          "lbl_reset_password_or": "OR",
          "lbl_update_profile_not_valid_email": "Email Is not Valid",
          "lbl_mfa_verify_sms_code": "SMSCode",
          "lbl_activate_password_no_last_name": "should not include part of Last Name",
          "lbl_forgot_password_or": "Or",
          "lbl_activate_password_one_uppercase_required": "Must contain at least one upper case letter",
          "lbl_other_email_setup_back_factor": "Back to Factors",
          "lbl_reset_password_click": "Click Here",
          "lbl_activate_password_resend_mail_success": "Re-Send Activation Mail Sent Successfully",
          "lbl_sms_setup_resend_code": "Resend Code",
          "lbl_create_account_info_message_410142331895808": "Create your profile to join the Unified Portal community!",
          "lbl_create_account_maximum_argument": "Maximum {0} characters are allowed ",
          "lbl_change_password_symbol": "include at least one symbol",
          "lbl_login_create_account": "Create Account",
          "lbl_change_password_privacy_policy": "Privacy Policy",
          "lbl_sms_setup_resend_sms": "Retry SMS in ",
          "lbl_reset_password_no_first_name": "No part of firstName",
          "lbl_update_profile_displayName": "Display name",
          "lbl_reset_password_min_password_length": "Length must be greater than {0} characters",
          "lbl_create_account_organization": "Organization",
          "lbl_mfa_multi_factor_setup": "SETUP",
          "lbl_forgot_password_submit": "Submit",
          "lbl_create_account_userType": "User type",
          "lbl_mfa_verify_email_resend": "Resend Email",
          "lbl_voice_setup_text_one": "Keeping PepsiCo safe and secure",
          "lbl_mfa_verify_error": "ERROR",
          "lbl_other_factor_sms_setup": "SET UP Voice Factor",
          "lbl_create_account_honorificPrefix": "Honorific prefix",
          "lbl_mfa_verify_sms_auth": "SMS Authentication",
          "lbl_other_factor_sms_activate": "ACTIVATE",
          "lbl_mfa_verify_call_code": "CallCode",
          "lbl_reset_password_password_invalid": "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
          "lbl_update_profile_nickName": "Nickname",
          "lbl_create_account_middleName": "Middle name",
          "lbl_voice_setup_please_select_country": "Please Select Country",
          "lbl_create_account_maximum_char": "Maximum 15 characters",
          "lbl_update_profile_website": "Website",
          "lbl_update_profile_zoneinfo": "Time Zone",
          "lbl_create_account_email_required": "Email is Required",
          "lbl_update_profile_profile": "Profile URL",
          "lbl_sms_setup_country_required": "Country Code is Required",
          "lbl_create_account_text_three": "By creating an account, you agree to our ",
          "lbl_email_setup_otp_setn": "OTP has been sent to Mail :",
          "lbl_mfa_other_factor_voice_call_verification": "Users will receive a one time passcode via voice call for verification",
          "lbl_update_profile_gender": "Gender",
          "lbl_create_account_login": "Username",
          "lbl_mfa_verify_required_code": "Passcode is Required",
          "lbl_create_account_email_exist": "Your provided Email {0} has already been used. Please use another Email address",
          "lbl_create_account_primaryPhone": "Primary phone",
          "lbl_forgot_password_user_not_found": "We're sorry. We weren't able to identify your account given the information provided.",
          "lbl_create_account_picture": "Picture URL",
          "lbl_change_password_min_password_length": "Length must be greater than {0} characters",
          "lbl_activate_password_new_password_required": "Please enter new password",
          "lbl_create_account_formatted": "Formatted",
          "lbl_activate_password_password_cpassword_not_match": "New and Confirm password must match",
          "lbl_update_profile_userName": "Username",
          "lbl_login_show_password": "Show Password",
          "lbl_mfa_other_factor_safe_and_secure": "Keeping PepsiCo safe and secure",
          "lbl_create_account_not_username": "Password Can't contain user name",
          "lbl_mfa_verify_security_question_required": "Security Question is Required",
          "lbl_create_account_email": "Email",
          "lbl_login_password": "Password",
          "lbl_mfa_verify_call_auth": "Call Authentication",
          "lbl_activate_password": "Activate Account",
          "lbl_other_security_question_setup_enable_security": "Enable Security",
          "lbl_create_account_city": "City",
          "lbl_mfa_other_factor_sms_call_verification": "Users will receive a one time passcode via SMS for verification",
          "lbl_create_account_birthdate": "Birthdate",
          "lbl_create_account_number": "include at least one number",
          "lbl_update_profile_primaryPhone": "Primary phone",
          "lbl_create_account_mobilePhone": "Mobile phone",
          "lbl_mfa_verify_resend_sms": "Retry SMS in ",
          "lbl_okta_verify_back_factor": "Back to Factors",
          "lbl_create_account_cancel": "Cancel",
          "lbl_change_password_one_symbol_required": "Must include at least one symbol",
          "lbl_okta_verify_okta_playstore": "Okta Verify from the Google Play Store",
          "lbl_mfa_verify_okta_verify": "Okta Verify",
          "lbl_create_account_mobile_required": "Mobile Number is Required",
          "lbl_activate_password_privacy_policy": "Privacy Policy",
          "lbl_mfa_verify_security_answer_required": "Answer is Required",
          "lbl_update_profile_success": "Profile Updated Successfully",
          "lbl_update_profile_zipcode": "Zip Code",
          "lbl_other_factor_sms_country_required": "Country Code is Required",
          "lbl_update_profile_comma_integer": "please enter comma separated integer values",
          "lbl_login_forgot_password": "Forgot Password",
          "lbl_other_factor_okta_setup": "OTHER FACTORS",
          "lbl_create_account_locality": "Locality",
          "lbl_activate_password_one_number_required": "Must include at least one number",
          "lbl_change_password_no_first_name": "should not include part of First Name",
          "lbl_update_profile_field_required": "This field is Required",
          "lbl_create_account_password": "Password",
          "lbl_activate_password_already_active": "User Already Activated",
          "lbl_change_password_current_password": "Current Password",
          "lbl_mfa_verify_success": "Message Sent Successfully",
          "lbl_voice_setup_back_factor": "Back to factors",
          "lbl_create_account_create_success": "Account Created Successfully",
          "lbl_create_account_lower_char": "include lower case characters",
          "lbl_update_profile_countryCode": "Country code",
          "lbl_mfa_multi_factor_recommended": "Recommended",
          "lbl_other_factor_voice_call_safe_and_secure": "Keeping PepsiCo safe and secure",
          "lbl_create_account_postal_code": "Postal Code",
          "lbl_mfa_verify_sms": "VERIFY SMS",
          "lbl_create_account_locale": "Locale",
          "lbl_okta_verify_setup_page": "OktaVerify SetUp Page",
          "lbl_update_profile_lastName": "Last name",
          "lbl_change_password_and": "and",
          "lbl_create_account_sign_in": "Sign In",
          "lbl_mfa_multi_factor_security": "For enhanced security, you will have to register for at least one of the below MFA factor for authenication. ",
          "lbl_update_profile_max_ten_char": "Maximum of 10 Characters allowed",
          "lbl_other_factor_voice_call_back_to_factors": "BACK TO FACTORS",
          "lbl_change_password_old_password_required": "Current password is Required",
          "lbl_mfa_verify_setup_other_factors": "SETUP OTHER FACTORS",
          "lbl_change_password_minumum_char": "be at least {0} characters long",
          "lbl_update_profile_postal_code": "Postal Code",
          "lbl_create_account_number_only": "please Enter numbers only",
          "lbl_create_account_min_five": "Minimum of 5 characters Required",
          "lbl_update_profile_zipCode": "Zip code",
          "lbl_mfa_verify_call_success": "Call Sent Successfully",
          "lbl_create_account_minumum_char": "At least 8 characters",
          "lbl_create_account_required_argument": "{0} is Required",
          "lbl_reset_password_create_account": "Create Account",
          "lbl_forgot_password_try_again": "Please Try Again !",
          "lbl_update_profile_cancel": "Cancel",
          "lbl_activate_password_current_password": "Current Password",
          "lbl_update_profile_phone_number": "Phone Number",
          "lbl_mfa_other_factor_security": "For enhanced security, you will have to register for at least one of the below MFA factor for authenication. ",
          "lbl_update_profile_costCenter": "Cost center",
          "lbl_update_profile_mobile_number": "Mobile Number",
          "lbl_update_profile_locale": "Locale",
          "lbl_create_account_title": "Title",
          "lbl_update_profile_mobile_required": "Mobile Number is Required",
          "lbl_email_setup_code_required": "Code is Required",
          "lbl_security_question_setup_mfa": "Set Up Security Factor",
          "lbl_update_profile_name": "Name",
          "lbl_sms_setup_mobile_number": "Mobile Number",
          "lbl_login_or": "or",
          "lbl_reset_password_one_number_required": "Must include at least one number",
          "lbl_create_account_symbol": "include at least one symbol",
          "lbl_login_email": "Email",
          "lbl_voice_setup_retry_call_in": "Retry Calling in ",
          "lbl_create_account_one_number_required": "Must include at least one number",
          "lbl_security_question_setup_select_one": "Please Select Security Question",
          "lbl_create_account": "Create Account ",
          "lbl_other_factor_voice_call_mobile_number": "Mobile Number",
          "lbl_other_security_question_setup_mfa": "Set Up Security Factor",
          "lbl_other_factor_okta_setup_back_factor": "Back to factors",
          "lbl_mfa_verify_security_answer": "Answer*",
          "lbl_activate_password_no_username": "should not include part of Email",
          "lbl_create_account_field_required": "This field is Required",
          "lbl_forgot_password_email": "Email Address",
          "lbl_other_security_question_setup_atleast_one": "Please Select Atleast One Question",
          "lbl_mfa_multi_factor_select_one": "Please Select Atlease One Factor!",
          "lbl_change_password_failed": "We're sorry. Your password couldn't be changed due to system error, please notify support about problem.",
          "lbl_update_profile_postalAddress": "Postal Address",
          "lbl_other_security_question_setup_anser": "Answer*",
          "lbl_update_profile_timezone": "Time zone",
          "lbl_update_profile_secondEmail": "Secondary ",
          "lbl_create_account_division": "Division",
          "lbl_security_question_setup_enable_security": "Enable Security",
          "lbl_activate_password_min_password_length": "Length must be greater than {0} characters",
          "lbl_create_account_policy_message_410142331895808": "",
          "lbl_update_profile_minimum_argument": "Atleast {0} characters are required",
          "lbl_create_account_name": "Name",
          "lbl_create_account_address": "Address",
          "lbl_create_account_zipcode": "Zip Code",
          "lbl_create_account_address_required": "Address Field Is Required",
          "lbl_activate_password_failed": "We're sorry. Your password couldn't be changed due to system error, please notify support about problem.",
          "lbl_change_password_new_password": "New Password",
          "lbl_activate_password_minumum_char": "be at least {0} characters long",
          "lbl_mfa_verify_email_otp_required": "Otp is Required",
          "lbl_forgot_password": "Forgot Password",
          "lbl_sms_setup_send_sms": "SEND SMS",
          "lbl_create_account_userAddress": "Address",
          "lbl_security_question_setup_back_factor": "Back to Factors",
          "lbl_sms_setup_sms_factor": "SET UP SMS Factor",
          "lbl_activate_password_one_lowercase_required": "Must contain at least one lower case letter",
          "lbl_mfa_multi_factor_show_other": "SHOW OTHER FACTORS",
          "lbl_forgot_password_check_mail": "Please Check your Mail",
          "lbl_mfa_verify_not_challenge": "Does Not Challenge me for Next",
          "lbl_mfa_verify_resend_call": "Retry Calling in ",
          "lbl_update_profile_middle_name": "Middle Name",
          "lbl_create_account_preferredLanguage": "Preferred language",
          "lbl_forgot_password_back_sign_in": "Back to Sign In?",
          "lbl_change_password_cancel": "Cancel",
          "lbl_update_profile_profileUrl": "Profile Url",
          "lbl_mfa_multi_factor_secure_option": "Okta verify is a secure MFA verification method developed by Okta to verify a user's identity through push notifications via the Okta verify mobile app. This is the most secure MFA option.",
          "lbl_create_account_upper_char": "include upper case characters",
          "lbl_update_profile_comma_string": "please enter comma separated string values",
          "lbl_mfa_multi_factor_sms_call_verification": "Users will receive a one time passcode via SMS for verification",
          "lbl_create_account_wallet_address": "Full Ethereum Wallet Address (0x374D49…)",
          "lbl_mfa_verify_email_submit": "Submit",
          "lbl_security_question_setup_safe_secure": "Keeping PepsiCo safe and secure",
          "lbl_update_profile_honorificPrefix": "Honorific prefix",
          "lbl_change_password_password_invalid": "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
          "lbl_update_profile_country": "Country",
          "lbl_mfa_verify_number_required": "Please enter correct Number",
          "lbl_update_profile_organization": "Organization",
          "lbl_create_account_zipCode": "Zip code",
          "lbl_other_factor_okta_setup_both_totp_and_push_authentication": "Setting Up Both Totp and Push Authentication",
          "lbl_mfa_verify_code": "VERIFY CODE",
          "lbl_mfa_verify": "VERIFY",
          "lbl_create_account_comma_integer": "please enter comma separated integer values",
          "lbl_update_profile_department": "Department",
          "lbl_create_account_family_name": "Family Name",
          "lbl_reset_password_one_uppercase_required": "Must contain at least one upper case letter",
          "lbl_forgot_password_link_sent": "Forgot Password link sent",
          "lbl_other_factor_voice_call_country_required": "Country Code is Required",
          "lbl_other_factor_voice_call_setup": "SET UP Voice Factor",
          "lbl_mfa_verify_verification_page": "Verification Page",
          "lbl_update_profile_street_address": "Street Address",
          "lbl_update_profile_firstName": "First name",
          "lbl_mfa_verify_email_retry": "Retry Sending Mail",
          "lbl_update_profile_update_account": "Update Account",
          "lbl_create_account_employeeNumber": "Employee number",
          "lbl_email_setup_activate_mail": "Activate EMAIL",
          "lbl_other_email_setup_activate_mail": "Activate Email",
          "lbl_activate_password_confirm_password": "Confirm Password",
          "lbl_create_account_mobile_number": "Mobile Number",
          "lbl_reset_password_failed": "We're sorry. Your password couldn't be changed due to system error, please notify support about problem.",
          "lbl_create_account_address1": "Address 1",
          "lbl_create_account_address2": "Address 2",
          "lbl_create_account_region": "Region",
          "lbl_other_factor_sms_mobile_number": "Mobile Number",
          "lbl_voice_setup_code_required": "Code is Required",
          "lbl_update_profile_state": "State",
          "lbl_create_account_first_name": "First Name",
          "lbl_activate_password_token_expired": "Token is Expired or used, Please Click on  Re-send Activation mail",
          "lbl_email_setup_back_factor": "Back to Factors",
          "lbl_update_profile_streetAddress": "Street address",
          "lbl_reset_password_re_password": "Re-enter Password",
          "lbl_mfa_verify_resend_code": "Resend Code",
          "lbl_create_account_min_password_length": "Length must be greater than {0} characters",
          "lbl_change_password_one_number_required": "Must include at least one number",
          "lbl_other_factor_okta_setup_send_push_notification": "SEND PUSH NOTIFICATION",
          "lbl_other_factor_sms_back_to_factors": "BACK TO FACTORS",
          "lbl_activate_password_origin_not_allow": "Origin Not Allowed to redirect",
          "lbl_security_question_setup_answer_required": "Answer is Required",
          "lbl_update_profile_manager": "Manager",
          "lbl_update_profile_termsCondition": "By creating an account, you agree to our {0}  and {1}",
          "lbl_mfa_verify_enter_code": "Enter Code Displayed On The Application",
          "lbl_change_password_cpassword_required": "Please enter confirm password",
          "lbl_other_email_setup": "SET UP Email Factor",
          "lbl_create_account_countryCode": "Country code",
          "lbl_mfa_multi_factor_safe_and_secure": "Keeping PepsiCo safe and secure",
          "lbl_reset_password_cpassword_required": "Please enter confirm password",
          "lbl_mfa_verify_send_sms": "Send SMS",
          "lbl_mfa_verify_choose_auth_type": "Choose Auth Type",
          "lbl_change_password": "Change Password",
          "lbl_change_password_upper_char": "include upper case characters",
          "lbl_reset_password_correct_details": "We're sorry. Your password couldn't be reset due to system error, please notify support about problem.",
          "lbl_voice_setup_call": "Call",
          "lbl_okta_verify_on_mobile": "onto your mobile device.",
          "lbl_change_password_no_last_name": "should not include part of Last Name",
          "lbl_activate_password_resend_activation_mail": "Re-Send Activation Mail",
          "lbl_reset_password_back_sign_in": "Back to Sign In?",
          "lbl_mfa_multi_factor": "Configure your Multi-Factor Authenication (MFA) Options",
          "lbl_update_profile_max_ten_numbers": "Maximum of 10 numbers",
          "lbl_security_question_setup_anser": "Answer*",
          "lbl_create_account_middle_name": "Middle Name",
          "lbl_forgot_password_create_account": "Create Account",
          "lbl_voice_setup_mobile_number_required": "Mobile Number is Required",
          "lbl_reset_password_no_last_name": "No part of lastName",
          "lbl_create_account_max_fifteen": "Maximum of 15 Characters are allowed",
          "lbl_update_profile_mobilePhone": "Mobile phone",
          "lbl_forgot_password_email_invalid": "Email Must Be Valid",
          "lbl_voice_setup_code": "code",
          "lbl_change_password_requirements": "Password Requirements",
          "lbl_other_factor_sms_mobile_number_required": "Mobile Number is Required",
          "lbl_update_profile_family_name": "Family Name",
          "lbl_create_account_general_required": "This field is required ",
          "lbl_create_account_one_uppercase_required": "Must contain at least one upper case letter",
          "lbl_email_setup_resend_email": "Resend Email",
          "lbl_sms_setup_mobile_required": "Mobile Number is Required",
          "lbl_login_google_sign_in": "LOGIN VIA GOOGLE",
          "lbl_update_profile_min_five": "Minimum of 5 characters Required",
          "lbl_update_profile_login": "Username",
          "lbl_reset_password_success": "Password Change Successful",
          "lbl_reset_password_one_symbol_required": "Must include at least one symbol",
          "lbl_mfa_verify_send_push_notification": "SEND PUSH NOTIFICATION",
          "lbl_create_account_country": "Country",
          "lbl_mfa_verify_question": "Question*",
          "lbl_mfa_multi_factor_enable_security_question": "Enables Security Question",
          "lbl_update_profile_last_name": "Last Name",
          "lbl_activate_password_symbol": "include at least one symbol",
          "lbl_change_password_number": "include at least one number",
          "lbl_create_account_nickname": "Nickname",
          "lbl_create_account_policy_message_363459124396032": "",
          "lbl_update_profile_picture": "Picture URL",
          "lbl_create_account_secondEmail": "Secondary ",
          "lbl_update_profile_division": "Division",
          "lbl_activate_password_upper_char": "include upper case characters",
          "lbl_create_account_text_one": "Create your profile to make it easier to get your Snacks quick! ",
          "lbl_login_need_help": "Need help ?",
          "lbl_update_profile_formatted": "Formatted",
          "lbl_sms_setup_back_factor": "BACK TO FACTORS",
          "lbl_create_account_minimum_argument": "be at least {0} characters long",
          "lbl_mfa_verify_send_call": "SEND CALL",
          "lbl_create_account_last_name": "Last Name",
          "lbl_mfa_verify_email": "Email",
          "lbl_email_setup_retry": "Retry Mail in",
          "lbl_create_account_state": "State",
          "lbl_create_account_password_required": "Password is Required",
          "lbl_create_account_nickName": "Nickname",
          "lbl_activate_password_and": "and",
          "lbl_voice_setup_country_required": "Country Code is Required",
          "lbl_create_account_subscription": "Yes! Sign me up to receive email from PepsiCo Tasty Rewards, PepsiCo and its brands so I never miss out on exciting updates, offers or sweepstakes",
          "lbl_create_account_given_name": "Given Name",
          "lbl_create_account_max_ten": "Maximum of 10 Characters allowed",
          "lbl_create_account_temrs_conditions": "Terms & Conditions",
          "lbl_update_profile_birthdate": "Birthdate",
          "lbl_other_security_question_setup_answer_required": "Answer is Required",
          "lbl_create_account_profileUrl": "Profile Url",
          "lbl_create_account_streetAddress": "Street address",
          "lbl_mfa_other_factor_secure_option": "Okta verify is a secure MFA verification method developed by Okta to verify a user's identity through push notifications via the Okta verify mobile app. This is the most secure MFA option.",
          "lbl_sms_setup_code_required": "Code is Required",
          "lbl_mfa_verify_session_expired": "Session Expired",
          "lbl_other_factor_okta_setup_okta_verify_setup": "OktaVerify SetUp Page",
          "lbl_reset_password_password_required": "Please enter new password",
          "lbl_voice_setup_error": "Error",
          "lbl_change_password_text_one": "By creating an account, you agree to our",
          "lbl_create_account_one_symbol_required": "Must include at least one symbol",
          "lbl_create_account_no_first_name": "should not include part of First Name",
          "lbl_update_profile_required_argument": "{0} is Required",
          "lbl_change_password_password_cpassword_not_match": "New and Confirm password must match",
          "lbl_login_required_email": "Email is required",
          "lbl_create_account_comma_string": "please enter comma separated string values",
          "lbl_mfa_multi_factor_voice_call_verification": "Users will receive a one time passcode via voice call for verification",
          "lbl_change_password_one_lowercase_required": "Must contain at least one lower case letter",
          "lbl_activate_password_back_signin": "Back to signIn",
          "lbl_other_factor_voice_call_please_select_country": "Please select Country",
          "lbl_other_email_setup_safe_secure": "Keeping PepsiCo safe and secure",
          "lbl_other_factor_voice_call": "CALL",
          "lbl_update_profile": "Update Profile",
          "lbl_create_account_zoneinfo": "Time Zone",
          "lbl_update_profile_first_name": "First Name",
          "lbl_activate_password_cpassword_required": "Please enter confirm password",
          "lbl_voice_setup_call_resend": "Resend Call",
          "lbl_activate_password_one_symbol_required": "Must include at least one symbol",
          "lbl_create_account_timezone": "Time zone",
          // "lbl_create_account_origin_not_allow": "Unauthorized: Access is denied due to invalid redirectUrl",
          "lbl_update_profile_only_number": "please Enter numbers only",
          "lbl_update_profile_city": "City",
          "lbl_voice_setup_mobile_number": "Mobile Number",
          "lbl_update_profile_honorificSuffix": "Honorific suffix",
          "lbl_update_profile_address1": "Address 1",
          "lbl_update_profile_address2": "Address 2",
          "lbl_reset_password_password_cpassword_not_match": "New and Confirm password must match",
          "lbl_create_account_first_name_required": "FirstName is Required",
          "lbl_update_profile_region": "Region",
          "lbl_forgot_password_email_required": "Email is Required",
          "lbl_other_factor_voice_call_mobile_number_required": "Mobile Number is Required",
          "lbl_activate_password_success": "Password Changed Successfully",
          "lbl_create_account_no_last_name": "should not include part of Last Name",
          "lbl_login_invalid_password": "Password is Required",
          "lbl_other_security_question_setup_safe_secure": "Keeping PepsiCo safe and secure",
          "lbl_update_profile_last_name_required": "Last Name is required",
          "lbl_activate_password_text_one": "By creating an account, you agree to our",
          "lbl_mfa_other_factor": "Configure your Other-Factor Authenication (OFA) Options",
          "lbl_update_profile_userType": "User type",
          "lbl_security_question_setup_atleast_one": "Please Select Atleast One Question",
          "lbl_create_account_termsCondition": "By creating an account, you agree to our {0}  and {1}",
          "lbl_activate_password_maximum_char": "Maximum 15 characters",
          "lbl_change_password_maximum_char": "Maximum 15 characters",
          "lbl_create_account_try_again": "Please Try Again ",
          "lbl_update_profile_employeeNumber": "Employee number",
          "lbl_okta_verify_app_instr": "Launch Okta Verify application on your mobile device and select Add an account.",
          "lbl_create_account_manager": "Manager",
          "lbl_create_account_password_require": "Password Requirement:",
          "lbl_reset_password_one_lowercase_required": "Must contain at least one lower case letter",
          "lbl_email_setup_enter_code": "Enter Code",
          "lbl_activate_password_temrs_conditions": "Terms & Conditions",
          "lbl_update_profile_managerId": "ManagerId",
          "lbl_create_account_and": "and",
          "lbl_email_setup_factor": "SetUp Email Factor",
          "lbl_mfa_multi_factor_okta_verify": "SETUP OKTA VERIFY",
          "lbl_voice_setup_call_factor": "SET UP Voice Factor",
          "lbl_create_account_password_invalid": "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
          "lbl_create_account_info_message_363459124396032": "Create your profile to join the Mic Drop community!",
          "lbl_create_account_department": "Department",
          "lbl_mfa_other_factor_select_one": "Please Select Atlease One Factor!",
          "lbl_mfa_verify_security_question": "Security Question",
          "lbl_update_profile_general_required": "This field is required ",
          "lbl_sms_setup_keeping_safe_and_secure": "Keeping PepsiCo safe and secure",
          "lbl_login_incorrect_cred": "Your password is incorrect or this account doesn't exist.",
          "lbl_create_account_last_name_required": "LastName is required",
          "lbl_mfa_verify_or_code": "Or Enter Code",
          "lbl_sms_setup_enter_code": "Enter Code",
          "lbl_login_activate_account": "Your account needs to be activated to login. Please activate it by clicking on the link in activation email. Click on âRe-send Activation Emailâ if you didn't get the activation email.",
          "lbl_change_password_success": "Password Changed Successfully",
          "lbl_forgot_password_click": "Click Here",
          "lbl_activate_password_user_activated": "Your account is already activated. Please login using most recent credentials.",
          "lbl_activate_password_number": "include at least one number",
          "lbl_change_password_lower_char": "include lower case characters",
          "lbl_create_account_text_two": "Been here before?",
          "lbl_activate_password_old_password_required": "Current password is Required",
          "lbl_create_account_no_username": "should not include part of Email",
          "lbl_sms_setup_verify": "VERIFY",
          "lbl_mfa_multi_factor_enable_email": "Enables Email Authentication",
          "lbl_change_password_confirm_password": "Confirm Password",
          "lbl_login_reactivate_account": "Reactivate Account",
          "lbl_login_back_to_signin": "Back to signIn",
          "lbl_login_resend_activation_mail_sent": "Re-Send Activation Mail Sent Successfully",
          "lbl_activate_password_incorrect_cred": "Incorrect Crendentials",
          "lbl_activate_password_try_again": "Please Try Again",
          "lbl_activate_password_invalid_status": "Invalid User Status Found",
          "lbl_create_account_verify_captcha": "Please verify Captcha",
          "lbl_create_account_confirm_password_required": "Confirm Password is Required",
          "lbl_create_account_password_must_match": "Passwords Must Match",
          "lbl_create_account_confirm_password": "Confirm Password",
          "lbl_login_please_activate_account": "Please Activate your Account by clicking on  Reactivate Account",
          "lbl_login_please_reactivate_account": "Please Reactivate your Account by clicking on Reactivate Account",
          "lbl_login_activation_faild": "We’re sorry. Your account couldn’t be activated due to system error, please notify support about problem",
          "lbl_login_activation_mail_success": "Activation Mail Sent Successfully",
          "lbl_login_error": "Internal server error",
          "lbl_login_user_already_active": "User Already Activated",
          "lbl_login_verify_captcha": "please verify captcha",
          "lbl_email_setup_origin_allowed": "Origin not allowed to redirect",
          "lbl_okta_verify_origin_allowed": "Origin not allowed to redirect",
          "lbl_mfa_multi_factor_origin_allowed": "Origin not allowed to redirect",
          "lbl_mfa_multi_factor_voice_call_authentication": "Voice Call Authentication",
          "lbl_mfa_multi_factor_sms_authentication": "SMS Authentication",
          "lbl_mfa_multi_factor_security_question": "Security Question",
          "lbl_mfa_multi_factor_email": "Email",
          "lbl_mfa_verify_otp_sent": "OTP has been sent to Mail",
          "lbl_mfa_verify_email_sent": "Email Sent",
          "lbl_mfa_verify_send_email": "Send Email",
          "lbl_security_question_origin_allowed": "Origin not allowed to redirect",
          "lbl_sms_setup_origin_allowed": "Origin not allowed to redirect",
          "lbl_voice_setup_origin_allowed": "Origin not allowed to redirect",
          "lbl_other_factor_okta_setup_push_notification": "SETUP PUSH NOTIFICATION",
          "lbl_other_security_question_minimum_char": "Miniumum 4 Characters Required",
          "lbl_mfa_other_factor_okta_verify": "Okta Verify (Recommended)",
          "lbl_mfa_other_factor_voice_call": "Voice Call Authentication",
          "lbl_mfa_other_factor_sms_authentication": "SMS Authentication",
          "lbl_mfa_other_factor_security_question": "Security Question",
          "lbl_mfa_other_factor_security_authentication": "Security Authentication",
          "lbl_mfa_other_factor_email": "Email",
          "lbl_mfa_other_factor_email_authentication": "Email Authentication",
          "lbl_mfa_other_factor_back_to_verification": "Back To Verification",
          "lbl_reset_password_no_user_name": "No part of userName"
}


const intialState = {
     LanguageData : [{
          code: 'default',
          data: DefaultLanguage
     }],
     isLoading: false,
     error: '',
     code:'en',
     pageName:'',
     tokenFlag:false,
     token:''
}

export const fetchLangReducer = ( state=intialState, action) => {
     switch(action.type){
          case ActionTypes.FETCH_LANGUAGE_REQUEST:
               return {...state, isLoading:true}
          case ActionTypes.FETCH_LANGUAGE_SUCCESS:
               return {...state, isLoading:false, LanguageData:[...state.LanguageData, {code:action.payload.code ,data: action.payload.data, pageName:action.payload.pageName}], code:action.payload.code,pageName:action.payload.pageName, error:''}
          case ActionTypes.FETCH_LANGUAGE_FAIL:
               return {...state, isLoading:false, LanguageData: [], error:action.payload}
          case ActionTypes.FETCH_LANG_CODE:
                return {...state, code: action.payload.langCode, pageName:action.payload.pageName}
          case ActionTypes.TOKEN_STATUS:
                return{...state, tokenFlag: action.payload.tokenStatus, token: action.payload.accessToken}
          default:
               return state

     }
}