import React from 'react'
import { useState, useEffect } from 'react'
import { fetchLangCode, fetchLanguageData } from '../../Redux/Actions/fetch_language'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'

function LanguageChange({ConsumerAppId, PageName, Lang}) {

  const { REACT_APP_API_ENDPOINT } = process.env;
    const[langCode, setLangCode] = useState(Lang)
    const[LanguageDetails, setLanguageDetails] = useState([])
      const handleLanguageChange = (e) => {
          const{value} = e.target
          setLangCode(value)
      }
      const LangData = useSelector( (state) => state)
      const dispatch = useDispatch()
      const langData =  LangData.LanguageData.LanguageData
      const CodeLang = LangData.LanguageData.code.trim()
      useEffect(() => {
         if(ConsumerAppId && PageName){
           if(langData.filter(item => item.code === langCode && item.pageName === PageName).length === 0){
              dispatch(fetchLanguageData(langCode, ConsumerAppId, PageName))
           }
           else{
            dispatch(fetchLangCode(langCode,PageName))
           }
         }
        //eslint-disable-next-line
      }, [langCode])

     //*** LANGUAGE DETAILS */ 
     
      const getAllLangDetail = async() => {
        await axios.get(`${REACT_APP_API_ENDPOINT}i18n/getLanguage/${ConsumerAppId}`)
         .then( res => {
           setLanguageDetails(res.data)
         }
         ).catch( err => toast.error("Please try again"))
      }


      useEffect(() => {
        getAllLangDetail()
        
        return () => {
          setLanguageDetails([])
        }
        //eslint-disable-next-line
      },[])


    return (
        <div className="d-flex justify-content-end  col-6" style={{position:'absolute', right:'0'}}>
            <div className="dropdown mt-4 col-lg-3 col-md-4 col-xs-12 col-sm-6">
            <select
            className="form-select"
            // style={{marginBottom:'10px', background: '#D79A2B', color:'#fff'}}
            name="verificationType"
            value={CodeLang}  
            onChange={handleLanguageChange}
            aria-label='Language'
          >
             {LanguageDetails && LanguageDetails.map( (Language) => 
              <option key={Language.langCode}  value={Language.langCode}>{Language.langName}</option> )}
          </select>
            </div>
          </div>
    )
}

export default LanguageChange
