import { ActionTypes } from "../Constants/ActionTypes";
import axios from "axios";

const { REACT_APP_API_ENDPOINT } = process.env;

export const fetchLanguageData = (code, id, PageName) => {
  return async function (dispatch) {
    dispatch(fetchUserRequest());
   await axios
      .get(`${REACT_APP_API_ENDPOINT}i18n/getPageLabel/${id}?lang=${code}&pageName=${PageName}`)
      .then((res) => {
        
        dispatch(fetchUserSuccess(res.data.data, code, PageName));
      })
      .catch((err) => {
        dispatch(fetchUserFail(err));
      });
  };
};

export const fetchUserRequest = () => {
  return {
    type: ActionTypes.FETCH_LANGUAGE_REQUEST,
  };
};

export const fetchUserSuccess = (data,code,pageName) => {
  return {
    type: ActionTypes.FETCH_LANGUAGE_SUCCESS,
    payload: {data,code, pageName}
  };
};

export const fetchUserFail = (error) => {
  return {
    type: ActionTypes.FETCH_LANGUAGE_FAIL,
    payload: error,
  };
};

export const fetchLangCode = (langCode,pageName) => {
  return {
     type: ActionTypes.FETCH_LANG_CODE,
     payload: {langCode,pageName}
  }
   
}

export const tokenStatus = (tokenStatus, accessToken) => {
  return {
     type: ActionTypes.TOKEN_STATUS,
     payload:{tokenStatus,accessToken}
  }
}



