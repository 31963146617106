import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import DefaultLogo from '../../../assests/images/DefaultLogo.png';
import DefaultBack from '../../../assests/images/background-img.png';
import { useSelector } from 'react-redux';
// import { fetchLanguageData } from "../../../Redux/Actions/fetch_language";
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import LanguageChange from '../../LanguageChange/LanguageChange';
import { Base64 } from 'js-base64';
import sessionRoute from '../../SessionRoute/sessionRoute';

//************** LOADER ***********/

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: 'red',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);

function SecurityQuestion() {
  //********* INTIALIZE VARIABLES */

  const classes = useStyles();
  const [intialLoader, setIntialLoader] = useState(false);

  const history = useHistory();
  // const dispatch = useDispatch()
  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_HOST_URL } = process.env;
  const [redirectUrl, setredirectUrl] = useState('');
  const [data4, setData4] = useState({});
  const [OriginAllowed, setOriginAllowed] = useState(false);
  const [OriginDomain, setOriginDomain] = useState(true);

  //*****  FETCHING DATA FROM QUERY PARAMETERS */

  const Data = useParams();
  const id = DOMPurify.sanitize(Data.id);
  const userId = DOMPurify.sanitize(Data.userId);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let stateToken = DOMPurify.sanitize(query.get('stateToken'));
  const Relay = DOMPurify.sanitize(query.get('Relay'));
  // const UserId = DOMPurify.sanitize(query.get('userId'));
  const LanguageCode = DOMPurify.sanitize(query.get('languageCode'));
  const RedirectURL = DOMPurify.sanitize(query.get('RedirectURL'));
  const location = useLocation()
  let factorsRequiredFlag = location?.state?.factorRequiredFlag
  let factorsRequired = location?.state?.factors

  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = 'lbl_security_question';
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === 'default' &&
    LangData.LanguageData.LanguageData.filter(
      (item) => item.code === CodeLang && item.pageName === pageName
    ).length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === 'default'
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang && item.pageName === pageName
          )[0]?.data
        : {};
  }

//***** REDIRECT URL   ******** */
const redirect_location = async () => {
  await axios
    .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
    .then((res) => {
      setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
      if(!RedirectURL){
      let data = res.data.appUrl.slice(0, -1);
      const FetchDomain = new URL(data).origin;
      TrustedRedirectUrl(FetchDomain);
      }
    })
    .catch((err) => console.log(err));
};

const TrustedRedirectUrl = async (domain) => {
  // setredirectUrl(domain);
  if (OriginDomain) {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}origins/get-origins?domain=${domain}`)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.map((originUrl) => {
            if (
              originUrl.origin === domain &&
              originUrl.status === 'ACTIVE'
            ) {
              setOriginAllowed(true);
            } else {
              setOriginAllowed(false);
            }
            return originUrl;
          });
        } else {
          setOriginAllowed(false);
          toast.error(langData?.lbl_security_question_origin_allowed, {
            position: 'top-center',
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: 'top-center',
        });
      });
  }
};

useEffect(() => {
  if (
    RedirectURL &&
    langData?.lbl_security_question_origin_allowed !== undefined
  ) {
    setOriginDomain(false);
    try {
      const FetchDomain = new URL(RedirectURL).origin;
      TrustedRedirectUrl(FetchDomain);
    } catch {
      redirect_location();
    }
  } 
  // else {
  //   if (langData?.lbl_create_account_origin_not_allow !== undefined) {
  //     redirect_location();
  //   }
  // }
  //eslint-disable-next-line
}, [langData?.lbl_security_question_origin_allowed]);

useEffect(() => {
  redirect_location();
  //eslint-disable-next-line
}, []);
  //  useEffect(() => {
  //    dispatch(fetchLanguageData(LanguageCode, id, PageName))
  //  //eslint-disable-next-line
  //  }, [])

  //**** FORMIK VALIDATION  */

  const initialValues = {
    Question: '',
    Answer: '',
  };
  const validationSchema = yup.object({
    Question: yup
      .string()
      .required(langData.lbl_security_question_setup_atleast_one),
    Answer: yup
      .string()
      .required(langData.lbl_security_question_setup_answer_required),
  });
  const onSubmit = (values) => {
    EnrollSecurity(values.Question, values.Answer);
  };

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    redirect_location();
    SecurityQuestion();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const Header_Color = data4.custom_properties?.headerColor;
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  //*****   SECURITY QUESTION DATA */

  const [SecurityData, setSecurityData] = useState([]);

  const SecurityQuestion = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}factors/get-security-questions/${userId}`)
      .then((res) => {
        setSecurityData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
//*** SET UP OTHER FACTORS PAGE  */

const OtherFactors = (obj) => {
  history.push({
    pathname: `/otherfactorsrequire/${userId}/${id}`,
    search:  `?stateToken=${stateToken}&languageCode=${LanguageCode ? LanguageCode : 'en'}`,
    state: {factors: obj}
  }
  );
};


  //*******  ENROLL SECURITY */

  const EnrollSecurity = async (question, answer) => {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/enroll-security-question-factor`,
        {
          stateToken: stateToken,
          factorType: 'question',
          provider: 'OKTA',
          question: question,
          answer: answer,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_ENROLL') {
          // if (id && LanguageCode) {
          //   let redirect = RedirectURL ? RedirectURL : redirectUrl
          //   window.location.href =
          //     redirect + (redirect.includes('?') ? `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` :`?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` );
          // }
          let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment === "REQUIRED")
            if(factors.length > 0){
                OtherFactors(res.data._embedded?.factors)
            }
        } else if (res.data.status === 'SUCCESS') {
          const sessionToken = res.data.sessionToken;
          if (sessionToken) {
            if (Relay) {
              window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
            } else {
              if (OriginAllowed) {
                let redirect = RedirectURL ? RedirectURL : redirectUrl;
                let profileWithId = {
                  ...res.data._embedded.user.profile,
                  userId: res.data._embedded.user.id
               }
                const EncodedData = `SessionToken=${
                  res.data.sessionToken
                }&profile=${JSON.stringify(profileWithId)}`;
                const RedirectData = encodeURI(Base64.encode(EncodedData));
                window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
              } else {
                toast.error(langData?.lbl_security_question_origin_allowed, {
                  position: 'top-center',
                });
              }
            }
          }
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session' && err?.response?.status === 401){
          toast.error(langData?.lbl_security_question_session_expired, {
            position: 'top-center',
          })
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  };

  //*** BACK TO FACTORS PAGE */

  const BacktoFactors = () => {
    if (Relay) {
      history.push(
        `/multifactor/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&RedirectURL=${
          RedirectURL ? RedirectURL : redirectUrl
        }&languageCode=${LanguageCode ? LanguageCode : 'en'}&Show=true`
      );
    } else {
      if (OriginAllowed) {
        if(factorsRequiredFlag){
          OtherFactors(factorsRequired)
        }else{
          history.push(
            `/multifactor/${userId}/${id}?stateToken=${stateToken}&RedirectURL=${
              RedirectURL ? RedirectURL : redirectUrl
            }&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&Show=true`
          );
        }
      } else {
        toast.error(langData?.lbl_security_question_origin_allowed, {
          position: 'top-center',
        });
      }
    }
  };

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: '#fff' }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={id}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : 'en'}
            />
            <div
              className="main-bg configure-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" onClick={() => sessionRoute(redirectUrl)} />
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {langData.lbl_security_question_setup_mfa}
                  </h2>
                </div>

                <div className="wrapper-inner">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(props) => (
                      <Form>
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.Question && props.errors.Question
                                  ? 'configure-sec form-group custom-select-dropdown has-danger'
                                  : 'form-group custom-select-dropdown'
                              }
                            >
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="Question"
                                value={props.values.Question}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              >
                                <option>
                                  {
                                    langData.lbl_security_question_setup_select_one
                                  }
                                </option>

                                {SecurityData &&
                                  SecurityData.map((item, key) => (
                                    <option key={key} value={item.question}>
                                      {item.questionText}
                                    </option>
                                  ))}
                              </select>
                              {props.touched.Question &&
                              props.errors.Question ? (
                                <div className="error-msg">
                                  {props.errors.Question}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>

                        <div
                          className={
                            props.touched.Answer && props.errors.Answer
                              ? 'form-group has-danger'
                              : 'form-group'
                          }
                        >
                          <input
                            className="custom-input"
                            type="text"
                            name="Answer"
                            value={props.values.Answer}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            style={{ color: Input_color }}
                            required
                          />
                          <label className="custom-label">
                            {langData.lbl_security_question_setup_anser}
                          </label>
                          {props.touched.Answer && props.errors.Answer ? (
                            <div className="error-msg">
                              {props.errors.Answer}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-yellow btn-block"
                            style={{
                              color: SignIn_button_color,
                              background: SignIn_button_backgroundColor,
                            }}
                          >
                            {
                              langData.lbl_security_question_setup_enable_security
                            }
                          </button>
                        </div>

                        <div className="form-group">
                          <button
                            className="btn btn-yellow-brd btn-block"
                            type="button"
                            onClick={BacktoFactors}
                            style={{ background: SecondaryButtonColor }}
                          >
                            {langData.lbl_security_question_setup_back_factor}
                          </button>
                        </div>
                        <div className="form-group text-center mb-0">
                          <p className="create-account text-decoration-none btn-text">
                            {langData.lbl_security_question_setup_safe_secure}
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SecurityQuestion;
