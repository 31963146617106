import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import DefaultLogo from '../../../assests/images/DefaultLogo.png';
import DefaultBack from '../../../assests/images/background-img.png';
import { useSelector } from 'react-redux';
// import { fetchLanguageData } from "../../../Redux/Actions/fetch_language";
import DOMPurify from 'dompurify';
import LanguageChange from '../../LanguageChange/LanguageChange';
import { Base64 } from 'js-base64';
import sessionRoute from '../../SessionRoute/sessionRoute.js'

//******* LOADER   **********/

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: 'red',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);

function PasscodeRequired() {
  //******** INTIALIZE VARIABLES *********** */

  const classes = useStyles();
  const [intialLoader, setIntialLoader] = useState(false);
  const history = useHistory();
  const [factors, setFactors] = useState([]);
  const [verifyType, setVerifyType] = useState('');
  const [data4, setData4] = useState({});
  const [sentSms, setResendSms] = useState(false);
  const [sentCall, setResendCall] = useState(false);
  const [sentMail, setResendEmail] = useState(false);
  const [timerSms, setTimerSms] = useState(false);
  const [timerCall, setTimerCall] = useState(false);
  const [timerEmail, setTimerEmail] = useState(false);
  const [counterSms, setCounterSms] = useState(30);
  const [counterCall, setCounterCall] = useState(30);
  const [CounterEmail, setCounterEmail] = useState(30);
  // const [isRemember, setisRemember] = useState(false);
  const [tokenEnable, settokenEnable] = useState(false);
  const [SecurityQuestion, setSecurityQuestion] = useState('');
  const [Email, setEmail] = useState('');
  // const dispatch = useDispatch()
  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_HOST_URL } = process.env;
  const FactorLifeTime = localStorage.getItem('FactorLifeTime');
  const CokkieDeviceExpirationTime = 0;
  const isRemember = false;
  const [redirectUrl, setredirectUrl] = useState('');

  //*****  FETCHING DATA FROM QUERY PARAMETERS */

  const Data = useParams();
  const id = DOMPurify.sanitize(Data.id);
  const userId = DOMPurify.sanitize(Data.userId);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let stateToken = DOMPurify.sanitize(query.get('stateToken'));
  const Relay = DOMPurify.sanitize(query.get('Relay'));
  const Activate = DOMPurify.sanitize(query.get('activate'));
  const LanguageCode = DOMPurify.sanitize(query.get('languageCode'));

  //******  GETTING LANGUAGE DATA FROM REDUX *********/

  const PageName = 'lbl_mfa_verify';
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === 'default' &&
    LangData.LanguageData.LanguageData.filter(
      (item) => item.code === CodeLang && item.pageName === pageName
    ).length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === 'default'
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang && item.pageName === pageName
          )[0]?.data
        : {};
  }

  //   useEffect( () => {
  //     dispatch(fetchLanguageData(LanguageCode, id, PageName))
  //    //eslint-disable-next-line
  //  },[])

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
      })
      .catch((err) => console.log(err));
  };

  useEffect( () => {
      redirect_location();
      //eslint-disable-next-line
  },[])

  //*** ENROLLED FACTORS API ********* */

  async function Factors() {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}factors/enroll-factor/${userId}`)
      .then((res) => {
        if (res.data.length > 0) {
          const Factors = res.data;
          setFactors(Factors);
          const question = Factors.filter(
            (item) => item.factorType === 'question'
          );
          setSecurityQuestion(question[0]?.profile?.questionText);
          const mail = Factors.filter((item) => item.factorType === 'email');
          setEmail(mail[0]?.profile?.email);
        }
      });
  }

  //****** DROP DOWN ITEMS ********* */

  const DropDownItem = factors
    .filter((item) => item.status === 'ACTIVE')
    .map((item) => item.factorType);

  const sorted_DropdownItems = [];
  DropDownItem.map((item) => {
    if (item === 'call') {
      sorted_DropdownItems.push(langData.lbl_mfa_verify_call_auth);
    }
    if (item === 'push' || item === 'token:software:totp') {
      sorted_DropdownItems.push(langData.lbl_mfa_verify_okta_verify);
    }
    if (item === 'sms') {
      sorted_DropdownItems.push(langData.lbl_mfa_verify_sms_auth);
    }
    if (item === 'question') {
      sorted_DropdownItems.push(langData.lbl_mfa_verify_security_question);
    }
    if (item === 'email') {
      sorted_DropdownItems.push(langData.lbl_mfa_verify_email);
    }

    return null;
  });

  const unique_DropdownItem = sorted_DropdownItems.filter(function (item, pos) {
    return sorted_DropdownItems.indexOf(item) === pos;
  });

  useEffect(() => {
    Factors();
    //eslint-disable-next-line
  }, []);
  //***** TOTP AND PUSH AUTHENTICATION ***********/

  const TotptFactorId = factors.find(
    (item) => item.factorType === 'token:software:totp'
  );

  async function verifyPasscode(passcode) {
    if (Activate === 'true' && TotptFactorId) {
      await axios
        .post(
          `${REACT_APP_API_ENDPOINT}factors/activate-totp-factor/${TotptFactorId.id}`,
          {
            stateToken: stateToken,
            passCode: passcode,
          }
        )
        .then((res) => {
          if(res.data.status === 'MFA_ENROLL'){
            let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
            if(factors.length > 0){
                OtherFactors(res.data._embedded?.factors)
            }
         }else if(res.data.status === "SUCCESS"){
          if (Relay) {
            window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
            clearInterval(intervalId1);
          }else{
            clearInterval(intervalId1);
            if (redirectUrl) {
              let redirect = redirectUrl;
              let profileWithId = {
                ...res?.data?._embedded?.user?.profile,
                userId: res.data._embedded.user.id
             }
              const EncodedData = `SessionToken=${
                res.data.sessionToken
              }&profile=${JSON.stringify(profileWithId)}`;
              const RedirectData = encodeURI(Base64.encode(EncodedData));
              window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
            } else {
              toast.error("Please provide Redirect url from Admin Portal", {
                position: 'top-center',
              });
            }
          }
         }
        })
        .catch((err) => {
          toast.error(err.response.data.errorSummary, {
            position: 'top-center',
          });
        });
    } else {
      await axios
        .post(
          `${REACT_APP_API_ENDPOINT}factors/verify-totp-factor/${TotptFactorId.id}`,
          {
            stateToken: stateToken,
            passCode: passcode,
            rememberDevice: isRemember,
            tokenTimeInSeconds: CokkieDeviceExpirationTime,
          }
        )
        .then((res) => {
          if(res.data.status === 'MFA_ENROLL'){
            let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
            if(factors.length > 0){
                OtherFactors(res.data._embedded?.factors)
            }
         }else if(res.data.status === "SUCCESS"){
          if (Relay) {
            window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
            clearInterval(intervalId1);
          }else{
            clearInterval(intervalId1);
            if (redirectUrl) {
              let redirect = redirectUrl;
              let profileWithId = {
                ...res?.data?._embedded?.user?.profile,
                userId: res.data._embedded.user.id
             }
              const EncodedData = `SessionToken=${
                res.data.sessionToken
              }&profile=${JSON.stringify(profileWithId)}`;
              const RedirectData = encodeURI(Base64.encode(EncodedData));
              window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
            } else {
              toast.error("Please provide Redirect url from Admin Portal", {
                position: 'top-center',
              });
            }
          }
         }
        })
        .catch((err) => {
          if(err?.response?.data?.errorCode === 'invalid_session'){
            toast.error(langData.lbl_mfa_verify_session_expired, {
              position: 'top-center',
            });
          }else{
            toast.error(err?.response?.data?.errorSummary, {
              position: 'top-center',
            });
          }
        });
    }
  }
  const PushFactorId = factors.find((item) => item.factorType === 'push');
  async function SendPushNot() {
    if (PushFactorId) {
      await axios
        .post(
          `${REACT_APP_API_ENDPOINT}factors/verify-push-factor/${PushFactorId.id}`,
          {
            stateToken: stateToken,
            rememberDevice: isRemember,
            tokenTimeInSeconds: CokkieDeviceExpirationTime,
          }
        )
        .then((res) => {
          if(res.data.status === 'MFA_ENROLL'){
            let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
            if(factors.length > 0){
                OtherFactors(res.data._embedded?.factors)
            }
         }else if(res.data.status === "SUCCESS"){
          if (Relay) {
            window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
            clearInterval(intervalId1);
          }else{
            clearInterval(intervalId1);
            if (redirectUrl) {
              let redirect = redirectUrl;
              let profileWithId = {
                ...res?.data?._embedded?.user?.profile,
                userId: res.data._embedded.user.id
             }
              const EncodedData = `SessionToken=${
                res.data.sessionToken
              }&profile=${JSON.stringify(profileWithId)}`;
              const RedirectData = encodeURI(Base64.encode(EncodedData));
              window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
            } else {
              toast.error("Please provide Redirect url from Admin Portal", {
                position: 'top-center',
              });
            }
          }
         }
        })
        .catch((err) => {
          if(err?.response?.data?.errorCode === 'invalid_session'){
            toast.error(langData.lbl_mfa_verify_session_expired, {
              position: 'top-center',
            });
          }else{
            toast.error(err?.response?.data?.errorSummary, {
              position: 'top-center',
            });
          }
        });
    }
  }

  let intervalId1;
  function VerifyPush() {
    intervalId1 = setInterval(SendPushNot, 2000);
  }

  const TotpFormik = useFormik({
    initialValues: {
      passCode: '',
    },
    validationSchema: yup.object({
      passCode: yup.string().required(langData.lbl_mfa_verify_required_code),
    }),
    onSubmit: (userInputData) => {
      verifyPasscode(userInputData.passCode);
    },
  });

  // **************SMS AUTHENTICATION*********************

  const SmsFactorId = factors.find((item) => item.factorType === 'sms');

  async function SendSms() {
    
    await axios
      .post(`${REACT_APP_API_ENDPOINT}factors/send-sms-otp/${SmsFactorId.id}`, {
        stateToken: stateToken,
      })
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData.lbl_mfa_verify_success, {
            position: 'top-center',
          });
          setResendSms(true);
          setTimerSms(true);
        }
      })
      .catch((err) => {
        setResendSms(false);
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  async function VerifySMS(Passcode) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/verify-sms-factor/${SmsFactorId.id}`,
        {
          stateToken: stateToken,
          passCode: Passcode,
          rememberDevice: isRemember,
          tokenTimeInSeconds: CokkieDeviceExpirationTime,
        }
      )
      .then((res) => {
        if(res.data.status === 'MFA_ENROLL'){
          let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
          if(factors.length > 0){
              OtherFactors(res.data._embedded?.factors)
          }
       }else if(res.data.status === "SUCCESS"){
        if (Relay) {
          window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
        }else{
          if (redirectUrl) {
            let redirect = redirectUrl;
            let profileWithId = {
              ...res?.data?._embedded?.user?.profile,
              userId: res.data._embedded.user.id
           }
            const EncodedData = `SessionToken=${
              res.data.sessionToken
            }&profile=${JSON.stringify(profileWithId)}`;
            const RedirectData = encodeURI(Base64.encode(EncodedData));
            window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
          } else {
            toast.error("Please provide Redirect url from Admin Portal", {
              position: 'top-center',
            });
          }
        }
       }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setVerifyType(value);
  };

  useEffect(() => {
    if (
      verifyType === langData.lbl_mfa_verify_call_auth ||
      verifyType === langData.lbl_mfa_verify_sms_auth ||
      verifyType === langData.lbl_mfa_verify_call_auth
    ) {
      settokenEnable(false);
    }
    //eslint-disable-next-line
  }, [verifyType]);

  const Smsformik = useFormik({
    initialValues: {
      SmspassCode: '',
    },
    validationSchema: yup.object({
      SmspassCode: yup.string().required(langData.lbl_mfa_verify_required_code),
    }),
    onSubmit: (userInputData) => {
      VerifySMS(userInputData.SmspassCode);
    },
  });

  //********* RESEND SMS  */
  async function ResendSms() {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/resend-sms-otp/${SmsFactorId.id}`,
        {
          stateToken: stateToken,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData.lbl_mfa_verify_success, {
            position: 'top-center',
          });
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  const handleResend = () => {
    if (counterSms === 0) {
      ResendSms();
    }
    setTimerSms(true);
    setCounterSms(30);
  };

  //********* VOICE AUTHENTICATION *********** */

  const CallFactorId = factors.find((item) => item.factorType === 'call');

  async function SendCall() {
    
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/send-call-otp/${CallFactorId.id}`,
        {
          stateToken: stateToken,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData.lbl_mfa_verify_call_success, {
            position: 'top-center',
          });
          setTimerCall(true);
          setResendCall(true);
        }
      })
      .catch((err) => {
        setResendCall(false);
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  async function VerifyCall(Passcode) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/verify-call-factor/${CallFactorId.id}`,
        {
          stateToken: stateToken,
          passCode: Passcode,
          rememberDevice: isRemember,
          tokenTimeInSeconds: CokkieDeviceExpirationTime,
        }
      )
      .then((res) => {
        if(res.data.status === 'MFA_ENROLL'){
          let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
          if(factors.length > 0){
              OtherFactors(res.data._embedded?.factors)
          }
       }else if(res.data.status === "SUCCESS"){
        if (Relay) {
          window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
        }else{
          if (redirectUrl) {
            let redirect = redirectUrl;
            let profileWithId = {
              ...res?.data?._embedded?.user?.profile,
              userId: res.data._embedded.user.id
           }
            const EncodedData = `SessionToken=${
              res.data.sessionToken
            }&profile=${JSON.stringify(profileWithId)}`;
            const RedirectData = encodeURI(Base64.encode(EncodedData));
            window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
          } else {
            toast.error("Please provide Redirect url from Admin Portal", {
              position: 'top-center',
            });
          }
        }
       }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  const Callformik = useFormik({
    initialValues: {
      CallpassCode: '',
    },
    validationSchema: yup.object({
      CallpassCode: yup
        .string()
        .required(langData.lbl_mfa_verify_required_code),
    }),
    onSubmit: (userInputData) => {
      VerifyCall(userInputData.CallpassCode);
    },
  });

  //********* RESEND CALL  */

  async function ResendCall() {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/resend-call-otp/${CallFactorId.id}`,
        {
          stateToken: stateToken,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData.lbl_mfa_verify_call_success, {
            position: 'top-center',
          });
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        };
      });
  }

  const handleResendCall = () => {
    if (counterCall === 0) {
      ResendCall();
    }
    setTimerCall(true);
    setCounterCall(30);
  };

  //******* TIMER IMPLEMENTATION SMS ********** */

  useEffect(() => {
    if (timerSms) {
      if (counterSms === 0) {
        setTimerSms(false);
      } else {
        const TimerSms =
          counterSms > 0 &&
          setInterval(() => setCounterSms(counterSms - 1), 1000);
        return () => clearInterval(TimerSms);
      }
    }
  }, [counterSms, timerSms]);

  //******* TIMER IMPLEMENTATION CALL ********** */

  useEffect(() => {
    if (timerCall) {
      if (counterCall === 0) {
        setTimerCall(false);
      } else {
        const TimerCall =
          counterCall > 0 &&
          setInterval(() => setCounterCall(counterCall - 1), 1000);
        return () => clearInterval(TimerCall);
      }
    }
  }, [counterCall, timerCall]);

  //*** SET UP OTHER FACTORS PAGE  */

  const OtherFactors = (obj) => {
    history.push({
      pathname:`/otherfactorsrequire/${userId}/${id}`,
      search:`?stateToken=${stateToken}&languageCode=${LanguageCode ? LanguageCode : 'en'}`,
      state:{factors : obj}
    }); 
  };

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const Header_Color = data4.custom_properties?.headerColor;
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  // ************HANDLING CHECK BOXES********************

  // const handleRemember = () => {
  //   setisRemember(!isRemember);
  // };

  // ********* TOKEN ENABLE***************

  const TokenEnable = () => {
    settokenEnable(!tokenEnable);
  };

  // *************** REMEMBER DEVICE *****************
  //eslint-disable-next-line
  let result_FactorLifeTime = '';
  function time_converter(time) {
    let days = Math.floor(time / (24 * 60));
    let hours = Math.floor((time % (24 * 60)) / 60);
    let minutes = Math.floor((time % (24 * 60)) % 60);

    if (days !== 0) {
      result_FactorLifeTime += days;
      if (days === 1) {
        result_FactorLifeTime += ' Day ';
      } else {
        result_FactorLifeTime += ' Days ';
      }
    }

    if (hours !== 0) {
      result_FactorLifeTime += hours;

      if (hours === 1) {
        result_FactorLifeTime += ' hour ';
      } else {
        result_FactorLifeTime += ' hours ';
      }
    }

    if (minutes !== 0) {
      result_FactorLifeTime += minutes;

      if (minutes === 1) {
        result_FactorLifeTime += ' Minute';
      } else {
        result_FactorLifeTime += ' Minutes';
      }
    }
  }

  time_converter(FactorLifeTime);

  //**********    Security Authentication     *********** */
  const SecurityFactorId = factors.find(
    (item) => item.factorType === 'question'
  );

  const SecurityVerify = async (answer) => {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/verify-security-question-factor/${SecurityFactorId.id}`,
        {
          stateToken: stateToken,
          answer: answer,
          rememberDevice: isRemember,
          tokenTimeInSeconds: CokkieDeviceExpirationTime,
        }
      )
      .then((res) => {
        if(res.data.status === 'MFA_ENROLL'){
          let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
          if(factors.length > 0){
              OtherFactors(res.data._embedded?.factors)
          }
       }else if(res.data.status === "SUCCESS"){
        if (Relay) {
          window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
        }else{
          if (redirectUrl) {
            let redirect = redirectUrl;
            let profileWithId = {
              ...res?.data?._embedded?.user?.profile,
              userId: res.data._embedded.user.id
           }
            const EncodedData = `SessionToken=${
              res.data.sessionToken
            }&profile=${JSON.stringify(profileWithId)}`;
            const RedirectData = encodeURI(Base64.encode(EncodedData));
            window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
          } else {
            toast.error("Please provide Redirect url from Admin Portal", {
              position: 'top-center',
            });
          }
        }
       }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  };

  const Securityformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: SecurityQuestion,
      answer: '',
    },
    validationSchema: yup.object({
      question: yup
        .string()
        .required(langData.lbl_mfa_verify_security_question_required),
      answer: yup
        .string()
        .required(langData.lbl_mfa_verify_security_answer_required),
    }),
    onSubmit: (userInputData) => {
      SecurityVerify(userInputData.answer);
    },
  });

  //**********   EMAIL AUTHENTICATION  ******** */

  const EmailFactorId = factors.find((item) => item.factorType === 'email');

  async function SendEmail() {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/send-email-otp/${EmailFactorId.id}`,
        {
          stateToken: stateToken,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData?.lbl_mfa_verify_otp_sent + `${Email}`, {
            position: 'top-center',
          });
          setTimerEmail(true);
          setResendEmail(true);
        }
      })
      .catch((err) => {
        setResendEmail(false);
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  async function ResendEmail() {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/resend-call-otp/${EmailFactorId.id}`,
        {
          stateToken: stateToken,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_CHALLENGE') {
          toast.success(langData?.lbl_mfa_verify_email_sent, {
            position: 'top-center',
          });
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  const handleResendEmail = () => {
    if (CounterEmail === 0) {
      ResendEmail();
    }
    setTimerEmail(true);
    setCounterEmail(30);
  };
  //****** TIMER IMPLEMENTATION EMAIL  */
  useEffect(() => {
    if (timerEmail) {
      if (CounterEmail === 0) {
        setTimerEmail(false);
      } else {
        const TimerEmail =
          CounterEmail > 0 &&
          setInterval(() => setCounterEmail(CounterEmail - 1), 1000);
        return () => clearInterval(TimerEmail);
      }
    }
  }, [CounterEmail, timerEmail]);

  async function VerifyEmail(Passcode) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/verify-call-factor/${EmailFactorId.id}`,
        {
          stateToken: stateToken,
          passCode: Passcode,
          rememberDevice: isRemember,
          tokenTimeInSeconds: CokkieDeviceExpirationTime,
        }
      )
      .then((res) => {
        // if (res.data.status === 'SUCCESS') {
        //   if (Relay) {
        //     window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
        //   }
        // }
         if(res.data.status === 'MFA_ENROLL'){
            let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment==="REQUIRED")
            if(factors.length > 0){
                OtherFactors(res.data._embedded?.factors)
            }
         }else if(res.data.status === "SUCCESS"){
          if (Relay) {
            window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${res.data.sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
          }else{
            if (redirectUrl) {
              let redirect = redirectUrl;
              let profileWithId = {
                ...res?.data?._embedded?.user?.profile,
                userId: res.data._embedded.user.id
             }
              const EncodedData = `SessionToken=${
                res.data.sessionToken
              }&profile=${JSON.stringify(profileWithId)}`;
              const RedirectData = encodeURI(Base64.encode(EncodedData));
              window.location.href = redirect + (redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
            } else {
              toast.error("Please provide Redirect url from Admin Portal", {
                position: 'top-center',
              });
            }
          }
         }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData.lbl_mfa_verify_session_expired, {
            position: 'top-center',
          });
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  const EmailFormik = useFormik({
    initialValues: {
      Otp: '',
    },
    validationSchema: yup.object({
      Otp: yup.string().required(langData.lbl_mfa_verify_email_otp_required),
    }),
    onSubmit: (userInputData) => {
      VerifyEmail(userInputData.Otp);
    },
  });

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: '#fff' }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={id}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : 'en'}
            />
            <div
              className="main-bg configure-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" onClick={() => sessionRoute(redirectUrl)} />
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {langData.lbl_mfa_verify_verification_page}
                  </h2>
                </div>

                <div className="wrapper-inner">
                  <div className="form-group custom-select-dropdown">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      name="verificationType"
                      onChange={handleChange}
                    >
                      <option defaultChecked>
                        {langData.lbl_mfa_verify_choose_auth_type}
                      </option>
                      {unique_DropdownItem.map((drop) => {
                        return (
                          <option key={drop} value={drop} >
                            {drop}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {tokenEnable ? (
                    <>
                      <div
                        className={
                          TotpFormik.touched.passCode &&
                          TotpFormik.errors.passCode
                            ? 'form-group has-danger'
                            : 'form-group'
                        }
                      >
                        <input
                          className="custom-input"
                          type="tel"
                          name="passCode"
                          value={TotpFormik.values.passCode}
                          onChange={TotpFormik.handleChange}
                          onBlur={TotpFormik.handleBlur}
                          style={{ color: Input_color }}
                          required
                        />
                        <label className="custom-label">
                          {langData.lbl_mfa_verify_input_code + '*'}
                        </label>
                        {TotpFormik.touched.passCode &&
                        TotpFormik.errors.passCode ? (
                          <div className="error-msg">
                            {TotpFormik.errors.passCode}
                          </div>
                        ) : null}
                        {/* <div className="form-group mt-3">
                          <label className="custom-checkbox">
                            {langData.lbl_mfa_verify_not_challenge}{' '}
                            {result_FactorLifeTime}
                            <input
                              type="checkbox"
                              checked={isRemember}
                              onChange={handleRemember}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div> */}

                        <button
                          className="btn btn-yellow btn-block mt-1"
                          type="button"
                          style={{ background: SignIn_button_backgroundColor }}
                          onClick={TotpFormik.handleSubmit}
                        >
                          {langData.lbl_mfa_verify}
                        </button>
                        <div className="form-group mt-3">
                          <button
                            className="btn btn-yellow btn-block"
                            type="button"
                            onClick={VerifyPush}
                            style={{ background: SecondaryButtonColor }}
                          >
                            {langData.lbl_mfa_verify_send_push_notification}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {verifyType === langData.lbl_mfa_verify_okta_verify ? (
                    <>
                      {!tokenEnable ? (
                        <>
                          {/* <div className="form-group mt-3">
                            <label className="custom-checkbox">
                              {langData.lbl_mfa_verify_not_challenge}{' '}
                              {result_FactorLifeTime}
                              <input
                                type="checkbox"
                                checked={isRemember}
                                onChange={handleRemember}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div> */}
                          <div className="form-group mb-1">
                            <button
                              className="btn btn-yellow btn-block"
                              type="button"
                              onClick={VerifyPush}
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_send_push_notification}
                            </button>
                          </div>
                          <p
                            style={{
                              color: 'black',
                              textDecoration: 'underline',
                              fontSize: '15px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                            onClick={TokenEnable}
                          >
                            {langData.lbl_mfa_verify_or_code}
                          </p>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {verifyType === langData.lbl_mfa_verify_sms_auth ? (
                    <>
                      <div
                        className={
                          Smsformik.touched.SmspassCode &&
                          Smsformik.errors.SmspassCode
                            ? 'form-group has-danger'
                            : 'form-group'
                        }
                      >
                        <input
                          className="custom-input"
                          type="tel"
                          name="SmspassCode"
                          value={Smsformik.values.SmspassCode}
                          onChange={Smsformik.handleChange}
                          onBlur={Smsformik.handleBlur}
                          style={{ color: Input_color }}
                          required
                        />
                        <label className="custom-label">
                          {langData.lbl_mfa_verify_sms_code + '*'}
                        </label>
                        {Smsformik.touched.SmspassCode &&
                        Smsformik.errors.SmspassCode ? (
                          <div className="error-msg">
                            {Smsformik.errors.SmspassCode}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="form-group mt-3">
                        <label className="custom-checkbox">
                          {langData.lbl_mfa_verify_not_challenge}{' '}
                          <p style={{ color: 'black' }}>
                            {result_FactorLifeTime}
                          </p>
                          <input
                            type="checkbox"
                            checked={isRemember}
                            onChange={handleRemember}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}

                      {sentSms ? (
                        <>
                          <div className="form-group mt-3">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={Smsformik.handleSubmit}
                              type="button"
                              style={{
                                color: SignIn_button_color,
                                background: SignIn_button_backgroundColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_sms}
                            </button>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              disabled={timerSms}
                              onClick={handleResend}
                              type="button"
                              style={{
                                color: SignIn_button_color,
                                background: SecondaryButtonColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_resend_code}
                            </button>
                            {timerSms ? (
                              <div className="error-msg">
                                {langData.lbl_mfa_verify_resend_sms}{' '}
                                {counterSms}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={SendSms}
                              style={{
                                background: SignIn_button_backgroundColor,
                                color: SignIn_button_color,
                              }}
                              type="button"
                            >
                              {langData.lbl_mfa_verify_send_sms}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}

                  {verifyType === langData.lbl_mfa_verify_call_auth ? (
                    <>
                      <div
                        className={
                          Callformik.touched.CallpassCode &&
                          Callformik.errors.CallpassCode
                            ? 'form-group has-danger'
                            : 'form-group'
                        }
                      >
                        <input
                          className="custom-input"
                          type="tel"
                          name="CallpassCode"
                          value={Callformik.values.CallpassCode}
                          onChange={Callformik.handleChange}
                          onBlur={Callformik.handleBlur}
                          style={{ color: Input_color }}
                          required
                        />
                        <label className="custom-label">
                          {langData.lbl_mfa_verify_call_code + '*'}
                        </label>
                        {Callformik.touched.CallpassCode &&
                        Callformik.errors.CallpassCode ? (
                          <div className="error-msg">
                            {Callformik.errors.CallpassCode}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="form-group mt-3">
                        <label className="custom-checkbox">
                          {langData.lbl_mfa_verify_not_challenge}{' '}
                          <p style={{ color: 'black' }}>
                            {result_FactorLifeTime}
                          </p>
                          <input
                            type="checkbox"
                            checked={isRemember}
                            onChange={handleRemember}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                      {sentCall ? (
                        <>
                          <div className="form-group mt-3">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={Callformik.handleSubmit}
                              type="button"
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_code}
                            </button>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              disabled={timerCall}
                              onClick={handleResendCall}
                              type="button"
                              style={{ background: SecondaryButtonColor }}
                            >
                              {langData.lbl_mfa_verify_resend_code}
                            </button>
                            {timerCall ? (
                              <div className="error-msg">
                                {langData.lbl_mfa_verify_resend_call}{' '}
                                {counterCall}{' '}
                              </div>
                            ) : null}
                          </div>
                          
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={SendCall}
                              type="button"
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_send_call}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}

                  {verifyType === langData.lbl_mfa_verify_security_question ? (
                    <>
                      <div>
                        <div
                          className={
                            Securityformik.touched.question &&
                            Securityformik.errors.question
                              ? 'form-group has-danger'
                              : 'form-group'
                          }
                        >
                          <input
                            className="custom-input"
                            type="text"
                            name="question"
                            value={Securityformik.values.question}
                            onChange={Securityformik.handleChange}
                            onBlur={Securityformik.handleBlur}
                            required
                          />
                          <label className="custom-label">
                            {' '}
                            {langData.lbl_mfa_verify_question}{' '}
                          </label>
                          {Securityformik.touched.question &&
                          Securityformik.errors.question ? (
                            <div className="error-msg">
                              {Securityformik.errors.question}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={
                            Securityformik.touched.answer &&
                            Securityformik.errors.answer
                              ? 'form-group has-danger'
                              : 'form-group'
                          }
                        >
                          <input
                            className="custom-input"
                            type="text"
                            name="answer"
                            value={Securityformik.values.answer}
                            onChange={Securityformik.handleChange}
                            onBlur={Securityformik.handleBlur}
                            style={{ color: Input_color }}
                            required
                          />
                          <label className="custom-label">
                            {' '}
                            {langData.lbl_mfa_verify_security_answer}{' '}
                          </label>
                          {Securityformik.touched.answer &&
                          Securityformik.errors.answer ? (
                            <div className="error-msg">
                              {Securityformik.errors.answer}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="form-group mt-3">
                        <label className="custom-checkbox">
                          {langData.lbl_mfa_verify_not_challenge}{' '}
                          <p style={{ color: 'black' }}>
                            {result_FactorLifeTime}
                          </p>
                          <input
                            type="checkbox"
                            checked={isRemember}
                            onChange={handleRemember}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                      <div className="form-group mt-3">
                        <button
                          className="btn btn-yellow btn-block mt-1"
                          onClick={Securityformik.handleSubmit}
                          type="button"
                          style={{ background: SignIn_button_backgroundColor }}
                        >
                          {langData.lbl_mfa_verify_security_submit}
                        </button>
                      </div>
                    </>
                  ) : null}

                  {verifyType === langData.lbl_mfa_verify_email ? (
                    <>
                      {sentMail ? (
                        <>
                          <div
                            className={
                              EmailFormik.touched.Otp && EmailFormik.errors.Otp
                                ? 'form-group has-danger'
                                : 'form-group'
                            }
                          >
                            <input
                              className="custom-input"
                              type="tel"
                              name="Otp"
                              value={EmailFormik.values.Otp}
                              onChange={EmailFormik.handleChange}
                              onBlur={EmailFormik.handleBlur}
                              style={{ color: Input_color }}
                              required
                            />
                            <label className="custom-label">
                              {langData.lbl_mfa_verify_email_code}
                            </label>
                            {EmailFormik.touched.Otp &&
                            EmailFormik.errors.Otp ? (
                              <div className="error-msg">
                                {EmailFormik.errors.Otp}
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="form-group mt-3">
                            <label className="custom-checkbox">
                              {langData.lbl_mfa_verify_not_challenge}{' '}
                              <p style={{ color: 'black' }}>
                                {result_FactorLifeTime}
                              </p>
                              <input
                                type="checkbox"
                                checked={isRemember}
                                onChange={handleRemember}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div> */}

                          <div className="form-group mt-3">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={EmailFormik.handleSubmit}
                              type="button"
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                            >
                              {langData.lbl_mfa_verify_email_submit}
                            </button>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              disabled={timerEmail}
                              onClick={handleResendEmail}
                              type="button"
                              style={{ background: SecondaryButtonColor }}
                            >
                              {langData.lbl_mfa_verify_email_resend}
                            </button>
                            {timerEmail ? (
                              <div className="error-msg">
                                {langData.lbl_mfa_verify_email_retry}{' '}
                                {CounterEmail}{' '}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block mt-1"
                              onClick={SendEmail}
                              type="button"
                              style={{ background: SecondaryButtonColor }}
                            >
                              {langData?.lbl_mfa_verify_send_email}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  {/* <div className="form-group">
                    <button
                      className="btn btn-yellow-brd btn-block mt-1"
                      type="button"
                      onClick={OtherFactors}
                    >
                      {langData.lbl_mfa_verify_setup_other_factors}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PasscodeRequired;
