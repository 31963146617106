import axios from "axios";
import  Axios   from "axios";
import { tokenStatus } from "../../Redux/Actions/fetch_language";

import forge from "node-forge";
import CryptoJS from 'crypto-js'

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDSQ62oIy9/q20Glj32k0wM/tXL
    pbSV8E5XEUmaIRl554wmUZDRB5OaP3MYxcZQdh1Sfrd3yJCkPj4sYlxrlSNQXlBh
    8Qv3kI2969pATjpRbJVVOl4YMx4d/6Mao7XBUDlmpguhsLnLyj1AH7+0U3s13/+R
    rtRVVPQ7yYpGDBMLJwIDAQAB
    -----END RSA PUBLIC KEY-----`;

export const encrypt = (plainText, key) => {
    const publicKey = forge.pki.publicKeyFromPem(key);
    return forge.util.encode64(
        publicKey.encrypt(plainText, "RSA-OAEP", {
          md: forge.md.md5.create(),
          mgf1: {
            md: forge.md.sha1.create()
          }
        })
      )
  };
  

  export const encryptAES = (msg) => {
        const key = CryptoJS.lib.WordArray.random(8).toString();
        const iv =  CryptoJS.lib.WordArray.random(8).toString();
    
        // encrypt some bytes using GCM mode
        const cipher = forge.cipher.createCipher('AES-GCM', key);
        cipher.start({
            iv: iv, 
             additionalData: 'nvn', // optional
             tagLength: 128 // optional, defaults to 128 bits
        }); 
        cipher.update(forge.util.createBuffer(msg));
        cipher.finish();
        const encrypted = cipher.output;
        const encodedB64 = forge.util.encode64(encrypted.data);
        const tag = cipher.mode.tag; 
        const tagB64 = forge.util.encode64(tag.data);
        return {
            key: encrypt(key, publicKey),
            iv : iv,
            tag: tagB64,
            encrypt: encodedB64,
        }
  };


const interceptor = (Store) => {

  const EncrptedData = encryptAES('Pep$!C0MY!DMUn!F!ED@dm!nP0rt@l')
  const data = {
    key: EncrptedData.key,
    tag:  EncrptedData.tag,
    iv: EncrptedData.iv,
    encrypt: EncrptedData.encrypt,
  }
  const {REACT_APP_API_ENDPOINT_SECURE} = process.env
  let tokenFlag = false
  let AccessToken=''
  function TokenFetch(){
    axios.post(`${REACT_APP_API_ENDPOINT_SECURE}get-token`,data).then( res => {
    tokenFlag= true
    AccessToken = res.data.access_token
    if(AccessToken){
     Store.dispatch(tokenStatus(true,AccessToken))
    }else{
      Store.dispatch(tokenStatus(false,''))
    }
  }).catch(err => {
     console.log({err})
  })        
}
 if(tokenFlag=== false && AccessToken === ''){
   TokenFetch()
 }
  Axios.interceptors.request.use( (config) => {
      config.headers.lang = Store.getState().LanguageData.code
      if(AccessToken && config.url.includes('/public')){
          config.headers.Authorization = "Bearer "+  AccessToken;
        return config;
    }
    return config;
   }, (error) =>{ 
   return Promise.reject(error)
  });

   Axios.interceptors.response.use((res) => {
    return res;
  },
   async (err) => {
    
    const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {  
          await axios.post(`${REACT_APP_API_ENDPOINT_SECURE}get-token`,data).then( res => {
              tokenFlag= true
              AccessToken = res.data.access_token
              if(res.config.url.includes('/public')){
                res.config.headers.Authorization = "Bearer "+  AccessToken
              }
            }).catch(err => {
               console.log({err})
            })        
            return Axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }}
      return Promise.reject(err);
    }
  );
}
//eslint-disable-next-line
export default {interceptor}