import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Field, Form, Formik } from "formik";
import "../../global1.css";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import { Backdrop, IconButton } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import { Base64 } from "js-base64";
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import LanguageChange from "../LanguageChange/LanguageChange";

//********** LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: "100%",
    },
  })
);

/******  CHANGING FAVICON DYNAMICALLY  */

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppTitle() {
  return document.getElementById("AppTitle");
}

function ActivateUserPassword() {
  //***** INITIALIZE VARIABELS */

  const { REACT_APP_API_ENDPOINT } = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const [data4, setData4] = useState({});
  const [redirectUrl, setredirectUrl] = useState("");
  const [signInRedirect, setSignInRedirect] = useState("");
  const [cancelRedirect, setCancelRedirect] = useState("");
  const [userData, setuserData] = useState("");
  const [userStatus, setUserStatus] = useState("");
  //  const dispatch = useDispatch()
  // PASSWORD COMPLEXITY
  const [passwordComplexity, setPaswordComplexity] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const[userNotFound, setuserNotFound] = useState(false)
  //  const [StateToken, setStateToken] = useState('')
  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  //******* TOKEN VALIDATION *******/
  const [OriginAllowed, setOriginAllowed] = useState(false);
  const [TokenValid, setTokenValid] = useState(true);
  const [Timer, setTimer] = useState(false);
  const [oktaConfigDetails, setoktaConfigDetails] = useState("");

  //*****  FETCHING DATA FROM QUERY PARAMETERS */
  const Data = useParams();
  const id = DOMPurify.sanitize(Data.id);
  const user_id = DOMPurify.sanitize(Data.user_id);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let token = DOMPurify.sanitize(query.get("token"));
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"));
  const redirect = DOMPurify.sanitize(query.get("redirect"));

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(
          res.data.activatedRedirectURL !== ""
            ? res.data.activatedRedirectURL
            : res.data.appUrl
        );
        setCancelRedirect(
          res.data.cancelRedirectURL !== ""
            ? res.data.cancelRedirectURL
            : res.data.appUrl
        );
        setSignInRedirect(res.data.signInRedirectURL);
        setOriginAllowed(true)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
        passwordPolicies(res.data.appBasicDetails?.oktaGroupID);
        setoktaConfigDetails(res.data.oktaConfigDetails);
        if (res.data?.appBasicDetails?.enableGoogleCaptcha) {
          captchaVisibility();
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const Header_Color = data4.custom_properties?.headerColor;
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor;
  const AppTitle = data4.appBasicDetails?.appName;

  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = "lbl_activate_password";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === "default" &&
    LangData.LanguageData.LanguageData.filter(
      (item) => item.code === CodeLang && item.pageName === pageName
    ).length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === "default"
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang && item.pageName === pageName
          )[0]?.data
        : {};
  }

  //  useEffect(() => {
  //    dispatch(fetchLanguageData(LanguageCode, id, PageName));
  //    //eslint-disable-next-line
  //  }, []);

  //***********  GETTING DYNAMIC USER DATA FROM OKTA */

  async function User_details() {
    let reCaptcha_token =''
    reCaptcha_token = await executeRecaptcha("");
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-user-detail/${user_id}/${id}?reCaptchaToken=${reCaptcha_token}`)
      .then((res) => {
        if(res.data.responseStatus === "FAILURE"){
          setLoader(false);
            toast.error(langData?.lbl_activate_password_user_not_found,{
              position:'top-center'
             })
           setuserNotFound(true)
        }else{
          setLoader(false);
          let Obj = {};
          Object.keys(res.data.profile).forEach((key) => {
            Obj = { ...Obj, [key]: res.data.profile[key] };
          });
          setuserData(Obj);
          setUserStatus(res.data.status);
        }
        
      })
      .catch((err) => {
        setLoader(false);
        if(err?.response?.data?.errorCode === "invalid-captcha"){
          toast.error('Invalid Captcha',{position:'top-center'});
        }
      });
  }

  useEffect(() => {
       User_details();
    // ActivateUser()
    // eslint-disable-next-line
  }, [langData]);
  

  //**** CHANGE PASSWORD API */
  const SetPassword = async (confirmPassword, token, reCaptcha_token) => {
    setLoader(true);
    await axios
      .post(`${REACT_APP_API_ENDPOINT}set-password/${id}`, {
        password: confirmPassword,
        token: token,
        reCaptchaToken: reCaptcha_token,
        userId:user_id
      })
      .then((res) => {
        setLoader(false);
        if (res.data.responseStatus === "SUCCESS") {
          if (userStatus === "PROVISIONED") {
            if (res.data.statusCode === 401) {
              setTokenValid(false);
              toast.info(langData.lbl_activate_password_token_expired, {
                position: "top-center",
              });
            } else {
              if (res.data.status === "SUCCESS") {
                toast.success(langData.lbl_activate_password_success, {
                  position: "top-center",
                });
                if (res.data.sessionToken && res.data._embedded.user.profile) {
                  const EncodedData = `SessionToken=${
                    res.data.sessionToken
                  }&profile=${JSON.stringify(res.data._embedded.user.profile)}`;
                  let RedirectData = encodeURI(Base64.encode(EncodedData));
                  window.location.href =
                    redirect +
                    (redirect.includes("?")
                      ? `&data=${RedirectData}`
                      : `?data=${RedirectData}`);
                }
              }else if(res.data.status === "MFA_ENROLL" && oktaConfigDetails.identityWithMfaFlag){
                if(OriginAllowed){
                    const user_id = res.data?._embedded?.user?.id;
                  history.push(
                    `/multifactor/${user_id}/${id}?stateToken=${
                      res.data.stateToken
                    }&RedirectURL=${
                      redirectUrl ? redirectUrl : redirect
                    }&Relay=&languageCode=${
                      LanguageCode ? LanguageCode : "en"
                    } &Show=false`
                  );
                }
              }else if (res.data.status === "MFA_ENROLL") {
                toast.success(langData.lbl_activate_password_success, {
                  position: "top-center",
                });
                if (res.data.stateToken && res.data._embedded.user.profile) {
                  const MFAEncodedData = `StateToken=${
                    res.data.stateToken
                  }&profile=${JSON.stringify(res.data._embedded.user.profile)}`;
                  let RedirectEncodedData = encodeURI(
                    Base64.encode(MFAEncodedData)
                  );
                  window.location.href =
                    redirect +
                    (redirect.includes("?")
                      ? `&data=${RedirectEncodedData}`
                      : `?data=${RedirectEncodedData}`);
                }
              } else if (res.data.status === "MFA_REQUIRED") {
                toast.success(langData.lbl_activate_password_success, {
                  position: "top-center",
                });
                if (OriginAllowed) {
                  const stateToken = res.data?.stateToken;
                  const userId = res.data?._embedded.user.id;
                  history.push(
                    `/passcoderequire/${userId}/${id}?stateToken=${stateToken}&languageCode=${
                      LanguageCode ? LanguageCode : "en"
                    }&Relay=&activate=false`
                  );
                }
              } else {
                const ErrorData = JSON.parse(res.data.messgae);
                if (ErrorData.errorCauses.length > 0) {
                  toast.error(ErrorData.errorCauses[0].errorSummary, {
                    position: "top-center",
                  });
                } else {
                  toast.error(res.data.messgae, {
                    position: "top-center",
                  });
                }
              }
            }
          } else if (userStatus === "ACTIVE") {
            toast.info(langData?.lbl_activate_password_user_activated, {
              position: "top-center",
            });
          }
        }else if(res.data.responseStatus === "FAILURE" && res.data.statusCode === 401){
              setTokenValid(false);
              toast.info(langData.lbl_activate_password_token_expired, {
                position: "top-center",
              });
        } else {
          toast.error(extractErrorSummary(res.data.messgae), { position: "top-center" });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(langData?.lbl_activate_password_failed, {
          position: "top-center",
        });
      });
  };

  function extractErrorSummary(jsonString) {
    try {
        const jsonData = JSON.parse(jsonString);
        if (jsonData.errorSummary) {
            return jsonData.errorSummary;
        } else {
            return jsonString;
        }
    } catch (error) {
        return jsonString;
    }
}
  const TrustedRedirectUrl = async (domain) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}origins/get-origins?domain=${domain}`)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.map((originUrl) => {
            if (originUrl.origin === domain && originUrl.status === "ACTIVE") {
              setOriginAllowed(true);
            } else {
              setOriginAllowed(false);
            }
            return originUrl;
          });
        } else {
          setOriginAllowed(false);
          toast.error(langData?.lbl_activate_password_origin_not_allow, {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-center",
        });
      });
  };

  useEffect(() => {
    if (redirect) {
      const FetchDomain = new URL(redirect).origin;
      TrustedRedirectUrl(FetchDomain);
    }
    //eslint-disable-next-line
  }, [langData]);

  //  const ActivateUser = async () =>{
  //   await axios.post(`${REACT_APP_API_ENDPOINT}activate-user`,{
  //     token:token
  //   }).then( res => {
  //     setStateToken(res.data.stateToken)
  //     if( res.data.errorMsg === 401){
  //        toast.error("Activation Link already Used/Expired. please click on resend Activation Mail", {
  //         position: "top-center",
  //       })
  //         setTokenValid(false)
  //     }
  //   }).catch( err => console.log(err))
  //  }

  // ************ FORM VALIDATION FORMIK *********

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  if (oktaConfigDetails?.identityFlagPassword) {
    delete initialValues.confirmPassword;
  }

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required(
        oktaConfigDetails?.identityFlagPassword
          ? langData?.lbl_activate_password_cpassword_required
          : langData.lbl_activate_password_new_password_required
      )
      .min(
        passwordComplexity.minLength,
        langData["lbl_activate_password_min_password_length"]?.replace(
          "{0}",
          passwordComplexity.minLength
        )
      )
      .test(
        "minNumber",
        langData.lbl_activate_password_one_number_required,
        (value) => {
          if (value && passwordComplexity.minNumber > 0) {
            const regex = /\d/g;
            let number = value?.match(regex);
            if (number && number.length >= passwordComplexity.minNumber) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        "minLowercase",
        langData.lbl_activate_password_one_lowercase_required,
        (value) => {
          if (value && passwordComplexity.minLowerCase > 0) {
            const regex = /[a-z]/g;
            let text = value.match(regex);
            if (text && text.length >= passwordComplexity.minLowerCase) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        "minUppercase",
        langData.lbl_activate_password_one_uppercase_required,
        (value) => {
          if (value && passwordComplexity.minUpperCase > 0) {
            let text = value.match(/[A-Z]/g);
            if (text && text.length >= passwordComplexity.minUpperCase) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        "minSymbol",
        langData.lbl_activate_password_one_symbol_required,
        (value) => {
          if (value && passwordComplexity.minSymbol > 0) {
            let text = value.match(/[^A-Z0-9a-z]/g);
            if (text && text.length >= passwordComplexity.minSymbol) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .test(
        "no_Username",
        langData.lbl_activate_password_no_username,
        (value) => {
          let Email = userData?.email;
          if(value && passwordComplexity.excludeUsername){
            if(Email !== ""){
              // const delimiters = ['.', ',', '-', '_', '#', '@'];
              const loginParts = Email?.split(/[@.\-_#,]/).filter(part => part.length >=3);
              const lowercaseLoginParts = loginParts?.map(part => part.toLowerCase());
              const passwordLowerCase = value?.toLowerCase();
              let userName_Flag = true
                  for (const loginPart of lowercaseLoginParts) {
                    if(passwordLowerCase?.includes(loginPart)){
                       userName_Flag = false;
                       break;
                    }
                  }
                  return userName_Flag
            }else{
              return true
            }
          }
          return true;
          // if (value && passwordComplexity.excludeUsername) {
          //   if (Email !== "") {
          //     if (
          //       value?.toLowerCase()?.includes(Email?.toLowerCase()) ||
          //       value?.toLowerCase()?.includes(id)
          //     ) {
          //       return false;
          //     } else {
          //       return true;
          //     }
          //   } else {
          //     return true;
          //   }
          // }
          // return true;
        }
      )
      .test(
        "no_Firstname",
        langData.lbl_activate_password_no_first_name,
        (value) => {
          let Firstname = userData.firstName;
          if (
            value &&
            passwordComplexity?.excludeAttributes?.length > 0 &&
            passwordComplexity.excludeAttributes.indexOf("firstName") !== -1
          ) {
            if (userData.firstName !== ""  ) {
              if (Firstname.length >=3 && value?.toLowerCase().includes(Firstname?.toLowerCase())) {
                console.log("ENTERED")
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .test(
        "no_Lastname",
        langData.lbl_activate_password_no_last_name,
        (value) => {
          if (
            value &&
            passwordComplexity?.excludeAttributes?.length > 0 &&
            passwordComplexity.excludeAttributes.indexOf("lastName") !== -1
          ) {
            if (userData.lastName !== "" ) {
              if (
               userData.lastName.length >=3 && value?.toLowerCase()?.includes(userData?.lastName?.toLowerCase())
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .test("SpaceExclude", "do not include spaces in Password", (val) => {
        if ((id === "163142638239744" || id === "915041708670976" || id === "824955038072832") && val) {
          let regex = /^[^\s]+$/g;
          let Data = val.match(regex);
          if (Data) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),

    confirmPassword: yup
      .string()
      .required(langData?.lbl_activate_password_cpassword_required)
      .oneOf(
        [yup.ref("newPassword"), null],
        langData?.lbl_activate_password_password_cpassword_not_match
      ),
  });

  if (oktaConfigDetails?.identityFlagPassword) {
    delete validationSchema.fields.confirmPassword;
  }

  const ActivateUserWithPassword = async (email, password, reCaptcha_token) => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}activate-user/${user_id}/${id}`, {
        email: email,
        password: password,
        reCaptchaToken: reCaptcha_token,
      })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          if (res.data.sessionToken && res.data._embedded.user.profile) {
            const EncodedData = `SessionToken=${
              res.data.sessionToken
            }&profile=${JSON.stringify(res.data._embedded.user.profile)}`;
            let RedirectData = encodeURI(Base64.encode(EncodedData));
            window.location.href =
              redirect +
              (redirect.includes("?")
                ? `&data=${RedirectData}`
                : `?data=${RedirectData}`);
          } else if (res.data.messgae.errorSummary) {
            toast.error(res.data.messgae.errorSummary, {
              position: "top-center",
            });
          }
        } else {
          if (res.data.status === 401) {
            toast.error(langData.lbl_activate_password_incorrect_cred, {
              position: "top-center",
            });
          } else {
            toast.error(res.data.messgae, {
              position: "top-center",
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false)
        toast.error(langData.lbl_activate_password_try_again, {
          position: "top-center",
        });
      });
  };

  axios.defaults.withCredentials = true;
  async function login(email, password) {
    setLoader(true);
    axios
      .post(
        `${REACT_APP_API_ENDPOINT}login/${id}`,
        {
          username: email,
          password: password,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setLoader(false);
        if (res.data.sessionToken && res.data._embedded.user.profile) {
          const EncodedData = `SessionToken=${
            res.data.sessionToken
          }&profile=${JSON.stringify(res.data._embedded.user.profile)}`;
          let RedirectData = encodeURI(Base64.encode(EncodedData));
          window.location.href =
            redirect +
            (redirect.includes("?")
              ? `&data=${RedirectData}`
              : `?data=${RedirectData}`);
        } else if (res.data.messgae.errorSummary) {
          toast.error(res.data.messgae.errorSummary, {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Incorrect Credentials", {
          position: "top-center",
        });
      });

    //**Ending */
  }

  //***** SUBMIT CHANGE PASSWORD API */
  const onSubmit = async (values, props) => {
    let reCaptcha_token = "";
    User_details();
    if (data4.appBasicDetails?.enableGoogleCaptcha) {
      if (!executeRecaptcha) {
        toast.error("Execute recaptcha not yet available", {
          position: "top-center",
        });
        return;
      }
      reCaptcha_token = await executeRecaptcha("");
    } else {
      reCaptcha_token = "";
    }
    if (oktaConfigDetails?.identityFlagPassword) {
      if (userData && userStatus === "STAGED") {
        ActivateUserWithPassword(
          userData?.email,
          values?.newPassword,
          reCaptcha_token
        );
      } else if (userData && userStatus === "ACTIVE") {
        login(userData?.email, values?.newPassword);
      } else {
        toast.error(langData.lbl_activate_password_invalid_status, {
          position: "top-center",
        });
      }
    } else if (
      oktaConfigDetails?.identityWithMfaFlag ||
      oktaConfigDetails?.identityFlag
    ) {
      SetPassword(values.newPassword, token, reCaptcha_token);
    }
    setTimeout(() => {
      props.resetForm();
    }, 1000);
  };

  //**** GETTING PASSWORD POLICIES */

  const passwordPolicies = async (GroupId) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}policies/get-password-policies`)
      .then((res) => {
        const policyObject = res.data.filter((item) => {
          const groups = item.conditions.people.groups.include;
          if (groups.filter((groupId) => groupId === GroupId).length > 0) {
            return true;
          }
          return false;
        });
        if (policyObject.length > 0) {
          setPaswordComplexity(policyObject[0].settings.password.complexity);
        } else {
          setPaswordComplexity(
            res.data[res.data.length - 1].settings.password.complexity
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //*** BACK TO APPLICATION HOME */

  const BackHome = () => {
    window.location.href = signInRedirect;
  };

  //** RESEND EMAIL **/
  const handleResendEmail = async () => {
    let reCaptcha_token = "";
    if (data4.appBasicDetails?.enableGoogleCaptcha) {
      if (!executeRecaptcha) {
        toast.error("Execute recaptcha not yet available", {
          position: "top-center",
        });
        return;
      }
      reCaptcha_token = await executeRecaptcha("");
    } else {
      reCaptcha_token = "";
    }
    setLoader(true);
    setTimer(true);
    setTimeout(() => {
      setTimer(false);
    }, 30000);
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}/reactivate-user-id/${user_id}/${id}?redirect=${
          redirect ? redirect : redirectUrl
        }&languageCode=${LanguageCode}&token=${reCaptcha_token}`
      )
      .then((res) => {
        setLoader(false);
        if (res.data.status === "PROVISIONED") {
          toast.success(langData?.lbl_activate_password_resend_mail_success, {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.errorCode === "E0000038") {
          setTokenValid(false);
          setTimer(true);
          toast.error(langData?.lbl_activate_password_already_active, {
            position: "top-center",
          });
        } else {
          toast.error(err.response.data.errorSummary, {
            position: "top-center",
          });
        }
      });
  };

  const cancel_home = () => {
    if (id && LanguageCode) {
      // window.location.href = RedirectURL ? RedirectURL :  redirectUrl
      window.location.href = cancelRedirect;
    }
  };

  //*********    FAVICON DYNAMIC CHANGE */

  const handlefavicon = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (logo) {
      favicon.href = logo;
    }
  };
  const handleAppTitle = () => {
    const Title = getAppTitle();
    if (AppTitle) {
      Title.innerText = AppTitle;
    }
  };

  useEffect(() => {
    handlefavicon();
    handleAppTitle();
    //eslint-disable-next-line
  }, [logo]);

  function captchaVisibility() {
    document.getElementsByClassName("grecaptcha-badge")[0].style.visibility =
      "visible";
  }

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={id}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : "en"}
            />
            <div
              className="main-bg change-password-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div>
                  {loader ? (
                    <Backdrop
                      className={classes.root}
                      sx={{ color: "#fff" }}
                      open
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : null}
                </div>
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" />
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {TokenValid
                      ? langData?.lbl_activate_password
                      : langData?.lbl_activate_password_reactivate_account}
                  </h2>
                </div>
                <div className="wrapper-inner">
                  {TokenValid ? (
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                    >
                      {(props) => (
                        <Form className="mb-0">
                          <Field>
                            {() => (
                              <div
                                className={
                                  props.touched.newPassword &&
                                  props.errors.newPassword
                                    ? "change-password-sec form-group has-danger"
                                    : "change-password-sec form-group "
                                }
                              >
                                <input
                                  className="custom-input"
                                  type={showPassword ? "text" : "password"}
                                  name="newPassword"
                                  id="newPassword"
                                  value={props.values.newPassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  style={{ color: Input_color }}
                                  required
                                  aria-label="newPassword"
                                  disabled={userNotFound}
                                />
                                <label
                                  className="custom-label"
                                  htmlFor="newPassword"
                                >
                                  {oktaConfigDetails?.identityFlagPassword
                                    ? langData?.lbl_activate_password_confirm_password +
                                      "*"
                                    : langData?.lbl_activate_password_new_password +
                                      "*"}
                                </label>
                                <IconButton
                                  className="change-password-sec eyeIcon"
                                  onClick={() => {
                                    setShowPassword(
                                      (previousState) => !previousState
                                    );
                                  }}
                                  aria-label="Show/Hide Password Icon"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                                {props.touched.newPassword &&
                                props.errors.newPassword ? (
                                  <div
                                    className="sign-in-sec error-msg"
                                    aria-label={props.errors.newPassword}
                                  >
                                    {props.errors.newPassword}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Field>
                          {!oktaConfigDetails?.identityFlagPassword ? (
                            <Field>
                              {() => (
                                <div
                                  className={
                                    props.touched.confirmPassword &&
                                    props.errors.confirmPassword
                                      ? "change-password-sec form-group has-danger"
                                      : "change-password-sec form-group "
                                  }
                                >
                                  <input
                                    className="custom-input"
                                    type={showPassword1 ? "text" : "password"}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    value={props.values.confirmPassword}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    style={{ color: Input_color }}
                                    required
                                    aria-label="confirmPassword"
                                    disabled={userNotFound}
                                  />
                                  <label
                                    className="custom-label"
                                    htmlFor="confirmPassword"
                                  >
                                    {langData?.lbl_activate_password_confirm_password +
                                      "*"}
                                  </label>
                                  <IconButton
                                    className="change-password-sec eyeIcon"
                                    onClick={() => {
                                      setShowPassword1(
                                        (previousState) => !previousState
                                      );
                                    }}
                                    aria-label="Show/Hide Password Icon"
                                  >
                                    {showPassword1 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                  {props.touched.confirmPassword &&
                                  props.errors.confirmPassword ? (
                                    <div
                                      className="sign-in-sec error-msg"
                                      aria-label={props.errors.confirmPassword}
                                    >
                                      {props.errors.confirmPassword}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Field>
                          ) : null}

                          <div className="pwd-requirement">
                            <p className="font-weight-bold">
                              {langData?.lbl_activate_password_requirements}
                            </p>
                            <ul className="pl-4 mb-0">
                              {passwordComplexity.minLength > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_minumum_char?.replace(
                                    "{0}",
                                    passwordComplexity.minLength
                                  )}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minLowerCase > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_lower_char}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minUpperCase > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_upper_char}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minNumber > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_number}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minSymbol > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_symbol}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeUsername ? (
                                <li>
                                  {langData?.lbl_activate_password_no_username}
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeAttributes &&
                              passwordComplexity.excludeAttributes.filter(
                                (item) => item === "firstName"
                              ).length > 0 ? (
                                <li>
                                  {
                                    langData?.lbl_activate_password_no_first_name
                                  }
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeAttributes &&
                              passwordComplexity.excludeAttributes.filter(
                                (item) => item === "lastName"
                              ).length > 0 ? (
                                <li>
                                  {langData?.lbl_activate_password_no_last_name}
                                </li>
                              ) : (
                                <></>
                              )}
                              {id === "163142638239744" ||
                              id === "915041708670976" || id === "824955038072832" ? (
                                <>
                                  <li>do not include spaces in Password</li>
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                          {/* <p className="privacy-terms">
                          {langData.lbl_activate_password_text_one}
                          <Link to="/#">
                            {" " + langData.lbl_activate_password_privacy_policy}
                          </Link>
                          {" " + langData.lbl_activate_password_and}{" "}
                          <Link to="/#">
                            {" " +
                              langData.lbl_activate_password_temrs_conditions}
                          </Link>
                        </p> */}

                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block"
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                              disabled={!OriginAllowed || userNotFound}
                            >
                              {langData?.lbl_activate_password}
                            </button>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-yellow-brd btn-block"
                              onClick={cancel_home}
                              type="button"
                              style={{ background: SecondaryButtonColor }}
                              aria-label={
                                langData?.lbl_activate_password_cancel
                              }
                            >
                              {langData?.lbl_activate_password_cancel}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                  {TokenValid ? null : (
                    <div className="form-group">
                      <button
                        className="btn btn-yellow btn-block"
                        style={{ background: SignIn_button_backgroundColor }}
                        onClick={handleResendEmail}
                        disabled={Timer}
                        aria-label={
                          langData?.lbl_activate_password_resend_activation_mail
                        }
                      >
                        {langData?.lbl_activate_password_resend_activation_mail}
                      </button>
                      <p className="before-sign-in">
                        <button
                          style={{
                            textDecoration: "underline",
                            border: "none",
                            background: "#fff",
                            color: SignIn_button_color,
                            fontWeight: "bolder",
                            textAlign: "right",
                            marginTop: "1%",
                          }}
                          onClick={BackHome}
                          aria-label={
                            langData?.lbl_activate_password_back_signin
                          }
                        >
                          {langData?.lbl_activate_password_back_signin}
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ActivateUserPassword;
