import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import Checkmark from "../../assests/images/check-mark.png"

import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import "../../global1.css";

import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";

import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import sessionRoute from "../SessionRoute/sessionRoute";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: '100%',
    },
  })
);

//******  CHANGING FAVICON DYNAMICALLY  */

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppTitle() {
  return document.getElementById("AppTitle");
}


function Forgot() {

  //********* INITIAL VARIABLES */
  const { REACT_APP_API_ENDPOINT } = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const [data4, setData4] = useState({});
  const [redirectUrl, setredirectUrl] = useState("");
  const [signInRedirect, setSignInRedirect] = useState('')
  const [Sentmsg, setSentmsg] = useState(false)
  
   const { executeRecaptcha } = useGoogleReCaptcha()
  //*****  FETCHING DATA FROM QUERY PARAMETERS */
  const Data = useParams()
  const id= DOMPurify.sanitize(Data.id);
  // const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"));

  //*****  FETCHING REDIRECT URL  */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
        setSignInRedirect(res.data.signInRedirectURL)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);



  //***** Getting Language Data */

  const PageName = "lbl_forgot_password";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (defaultLang === "default" && LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0) {
    langData = LangData.LanguageData.LanguageData.filter((item) => item.code === "default")[0]?.data;
  } else {
    langData =LangData.LanguageData.LanguageData.length > 0? LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data: {};
  }
  // useEffect(() => {
  //   dispatch(fetchLanguageData(LanguageCode, id, PageName));
  //   //eslint-disable-next-line
  // }, []);

  //*******  CHECKING USER IS PRESENT OR NOT AND CALLING FORGOR API */


  async function fetchData(email, reCaptchaToken) {
    setLoader(true);
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}user-forgot-password/${id}?email=${encodeURIComponent(email)}&languageCode=${LanguageCode ? LanguageCode : 'en'}&domain=${window.location.origin}&reCaptchaToken=${reCaptchaToken}`
      )
      .then((res) => {
        setLoader(false);
        if (res.data.responseStatus === "SUCCESS") {
          setTimeout( () => {
            setSentmsg(true)
          }, 500)
        } else {
           if(res.data.errorCode === 'invalid-captcha'){
              toast.error(res.data.messgae,{
                position:"top-center"
            })
           }else{
              toast.error(langData.lbl_forgot_password_user_not_found, {
                position: "top-center",
              });
           }
        }
      })
      .catch((err) => {
        toast.error(langData.lbl_forgot_password_try_again, {
          position: "top-center",
        });
        setLoader(false);
      });
  }

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES *
  const Header_Color = data4.custom_properties?.headerColor 
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const AppTitle = data4.appBasicDetails?.appName;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;



  //****** FORMIK FORM VALIDATION  */

  const initialValues = {
    email: ""
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(langData?.lbl_forgot_password_email_required)
      .email(langData?.lbl_forgot_password_email_invalid)
  })

  const onSubmit = async (values, props) => {
    let reCaptcha_token = ""
    if(data4.appBasicDetails?.enableGoogleCaptcha){
      if (!executeRecaptcha) {
       toast.error(langData?.lbl_forgot_password_captcha_not_available, {
         position: 'top-center'
       })
       return;
     }
     reCaptcha_token = await executeRecaptcha("");
   }else{
    reCaptcha_token = ""
   }
    fetchData(values.email, reCaptcha_token)
    setTimeout( () => {
      props.resetForm()
     },1000)
     
  }

//********   REDIRECT TO SIGN IN */
  const redirect_sigin = () => {
    if( id && LanguageCode){
    // window.location.href =redirectUrl + (redirectUrl.includes('?') ?  `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` : `?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}`);
      window.location.href= signInRedirect
  }
  };

  //*********    FAVICON DYNAMIC CHANGE */

  const handlefavicon = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (logo) {
      favicon.href = logo;
    }
  };
  const handleAppTitle = () => {
    const Title = getAppTitle();
    if (AppTitle) {
      Title.innerText = AppTitle;
    }
  };

  useEffect(() => {
    handlefavicon();
    handleAppTitle();
    //eslint-disable-next-line
  }, [logo]);

  function captchaVisibility(){
    document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
   }

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
            
          </Helmet>
          <div className="body" style={{fontFamily: fontFamily}}>
          <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'}/>
            <div>
              {loader ? (
                <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : null}
            </div>
            <div
              className="main-bg forgot-password-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img 
                    src={logo ? logo : DefaultLogo} 
                    alt="Pepsi Micdrop Logo" 
                    onClick={() => sessionRoute(data4?.appBasicDetails?.appRedirectionURL)}
                    />
                  </div>
                  <h2 className="snacks-title" style={{color: Header_Color,fontFamily: fontFamily}} >{langData?.lbl_forgot_password}</h2>
                </div>
                <div className="wrapper-inner">
                  <Formik 
                   initialValues={initialValues}
                   onSubmit={onSubmit}
                   validationSchema={validationSchema} 
                  >
                    {
                      (props) => (
                       <Form className="mb-0">
                         <Field>
                         {
                           () => (
                            <div
                             className={ props.touched.email && props.errors.email
                              ? "forgot-password-sec form-group has-danger" 
                                : "forgot-password-sec form-group"
                            }
                            >
                              <input 
                              className="custom-input" 
                              type="text"
                              required
                              name="email"
                              id='email'
                              value={props.values.email}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              style={{ color: Input_color }} 
                              aria-label="email"
                              />
                              <label className="custom-label" htmlFor="email">
                                {langData?.lbl_forgot_password_email + "*"}
                              </label>
                              {props.touched.email && props.errors.email ? (
                              <div className="forgot-password-sec error-msg" aria-label={props.errors.email}>
                                {props.errors.email}
                              </div>
                            ) : null}
                            </div>

                           )
                         }
                         </Field>
                         
                    <div className="form-group">
                      <button className="btn btn-yellow btn-block" style={{color: SignIn_button_color, backgroundColor: SignIn_button_backgroundColor}} >{langData?.lbl_forgot_password_submit}</button>
                    </div>
                    <div className="form-group d-flex justify-content-end align-items-center">
                      <span className="back-sign-in">
                        {langData?.lbl_forgot_password_back_sign_in}
                      </span>
                      <button
                        type="button"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          color: "#000000",
                          fontSize: "16px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        onClick={redirect_sigin}
                        aria-label={langData?.lbl_forgot_password_click}
                      >
                        {langData?.lbl_forgot_password_click}
                      </button>
                    </div>
                    <div className="form-group">
                      <div className="or">
                        <span>{langData?.lbl_forgot_password_or}</span>
                      </div>
                    </div>
                    <div className="form-group text-center mb-0">
                      <Link
                        to={`/create-account/${id}?languageCode=${LanguageCode ? LanguageCode : 'en'}&redirectUrl=${redirectUrl}`}
                        className="create-account btn-text"
                      >
                        {langData?.lbl_forgot_password_create_account}{" "}
                      </Link>
                    </div>
                       </Form>
                      )
                    }
                  </Formik>
                </div>
              </div>
              {
                 Sentmsg ? 
                 <div className="notification-bar">
                 <p>
                   <img src={Checkmark} alt="Checkmark" />{langData?.lbl_forgot_password_link_sent}
                 </p>
                 </div>
                 : 
                 null
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Forgot;
