import React, { useState, useEffect } from "react";
import {  useHistory, useLocation, useParams } from "react-router";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import DefaultLogo from "../../../../assests/images/DefaultLogo.png";
import DefaultBack from "../../../../assests/images/background-img.png";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../../../Redux/Actions/fetch_language";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import LanguageChange from "../../../LanguageChange/LanguageChange";
import sessionRoute from "../../../SessionRoute/sessionRoute";


//******* LOADER ********* */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      position: "absolute",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);

function SecurityQuestionOther() {

  //********* INITIAL VARIABLES *****/
    const classes = useStyles();
    const [intialLoader, setIntialLoader] = useState(false);
    const history = useHistory();
    // const dispatch = useDispatch()
    const { REACT_APP_API_ENDPOINT } = process.env;
    const [data4, setData4] = useState({});

    //*****  FETCHING DATA FROM QUERY PARAMETERS */
  
    const Data = useParams()
  const  id  = DOMPurify.sanitize(Data.id);
  const userId = DOMPurify.sanitize(Data.userId)
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let stateToken =DOMPurify.sanitize(query.get("stateToken"));
    const Relay = DOMPurify.sanitize(query.get("Relay"));
    const LanguageCode = DOMPurify.sanitize(query.get("languageCode"))
  
     //******  GETTING LANGUAGE DATA FROM REDUX *****/
  
     const PageName = "lbl_other_security_question";
     let langData;
     const LangData = useSelector((state) => state);
     const CodeLang = LangData.LanguageData.code;
     const pageName = LangData.LanguageData.pageName;
     const defaultLang = LangData.LanguageData.LanguageData[0].code;
     if (
       defaultLang === "default" && LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0
     ) {
       langData = LangData.LanguageData.LanguageData.filter((item) => item.code === "default")[0]?.data;
     } else {
       langData = LangData.LanguageData.LanguageData.length > 0 ? LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data: {};
     }
   
    //  useEffect(() => {
    //    dispatch(fetchLanguageData(LanguageCode, id, PageName))
    //  //eslint-disable-next-line
    //  }, [])


//******* FORMIK VALIDATION ************* */ 

  const initialValues = {
    Question: "",
    Answer: "",
  }
  const validationSchema = yup.object({
    Question: yup.string().required(langData.lbl_other_security_question_setup_atleast_one),
    Answer: yup.string().required(langData?.lbl_other_security_question_setup_answer_required).min(4, langData.lbl_other_security_question_minimum_char),
  })
  const onSubmit = (values) => {
    EnrollSecurity(values.Question, values.Answer)
  }

  
  

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    SecurityQuestion()
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const Header_Color = data4.custom_properties?.headerColor 
  const SecondaryButtonColor= data4.custom_properties?.secondaryButtonColor
  const Input_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;


 
  //*****   SECURITY QUESTION DATA */

  const [SecurityData, setSecurityData] = useState([])

   const SecurityQuestion = async() => {
       await axios.get(`${REACT_APP_API_ENDPOINT}factors/get-security-questions/${userId}`)
       .then(res => {
            setSecurityData(res.data)
       }).catch(  err => {
            console.log(err)
       })

   }

   //*******  ENROLL SECURITY */

   const EnrollSecurity = async (question, answer) => {
        await axios.post(`${REACT_APP_API_ENDPOINT}factors/user-enroll-security-question-factor/${userId}`,{
            factorType: "question",
            provider: "OKTA",
            question: question,
            answer: answer
        }).then( res => {
            if(res.data.status === "ACTIVE") {
                history.push(
                  `/passcode/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&activate=false`
                );
              }

        }).catch( err => {
          if(err?.response?.data?.errorCode === 'invalid_session' && err?.response?.status === 401){
            toast.error(langData?.lbl_other_security_question_session_expire, {
              position: 'top-center',
            })
          }else{
            toast.error(err?.response?.data?.errorSummary, {
              position: 'top-center',
            });
          }
        })
   }

   //******* BACK TO FACTORS  ********* */

   const BacktoFactors = () => {
    history.push(`/otherfactors/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}`);
  };

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{fontFamily: fontFamily}} >
          <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'}/>
          <div className="main-bg configure-sec" style={{ backgroundImage: `url(${BackgroundImage})`, height:'100vh'}}>
        <div className="white-wrapper">
            <div className="text-center">
                <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" onClick={() => sessionRoute(data4?.appBasicDetails?.appRedirectionURL)} />
                </div>
                <h2 className="snacks-title" style={{fontFamily: fontFamily, color:Header_Color}}>{langData.lbl_other_security_question_setup_mfa}</h2>                
            </div>
            
            
            <div className="wrapper-inner">

              <Formik
              initialValues={initialValues}
              validationSchema= { validationSchema}
              onSubmit={onSubmit}  
              >
                {
                  (props) => (

                    <Form>
                      <Field>
                        {
                           () => (
                            <div className={props.touched.Question && props.errors.Question ?
                              "configure-sec form-group custom-select-dropdown has-danger" : "form-group custom-select-dropdown"
                            }>                        
                            <select 
                            className="form-control" 
                            id="exampleFormControlSelect1"
                            name='Question'
                            value={props.values.Question} 
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            >
                                  <option >{langData.lbl_other_security_question_setup_select_one}</option>
                                  
                                      {
                                        SecurityData && SecurityData.map( (item, key) => (
                                               <option key={key} value={item.question}>{item.questionText}</option>
                                         ))
                                      }
                                                        
                            </select>
                            {
                              props.touched.Question && props.errors.Question ? (
                                <div className='error-msg'>
                                  {props.errors.Question}
                                </div>
                              )
                              : null
                            }
                          </div>
                           )
                        }
                      </Field>
                     
                          <div className={props.touched.Answer && props.errors.Answer
                            ? "form-group has-danger" : "form-group"
                          }>
                                <input 
                                   className="custom-input" 
                                   type="text" 
                                   name='Answer'
                                   value={props.values.Answer}
                                   onChange={props.handleChange}
                                   onBlur={props.handleBlur} 
                                   style={{color:Input_color }}
                                   required 
                                   />
                                <label className="custom-label">{langData.lbl_other_security_question_setup_anser}</label>
                                {
                                  props.touched.Answer && props.errors.Answer ?
                                  (
                                    <div className="error-msg">{props.errors.Answer}</div> 
                                  )
                                  : null
                                }
                          </div>
                          <div className="form-group">
                              <button className="btn btn-yellow btn-block" style={{background: SignIn_button_backgroundColor}}>{langData.lbl_other_security_question_setup_enable_security}</button>
                          </div>

                      
                      

                    <div className="form-group">
                        <button className="btn btn-yellow-brd btn-block" type="button" onClick ={BacktoFactors} style={{background: SecondaryButtonColor}} >{langData.lbl_other_security_question_setup_back_factor}</button>
                    </div>
                    <div className="form-group text-center mb-0">
                        <p className="create-account text-decoration-none btn-text">{langData.lbl_other_security_question_setup_safe_secure}</p>
                     </div>
                    </Form>
                  )
                }

              </Formik>
            </div>
        </div>
    </div>
    </div>
        </>
      )}
    </div>
  );
}
 

export default SecurityQuestionOther