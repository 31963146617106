import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useFormik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import DefaultLogo from '../../../assests/images/DefaultLogo.png';
import DefaultBack from '../../../assests/images/background-img.png';
import { useSelector } from 'react-redux';
// import { fetchLanguageData } from "../../../Redux/Actions/fetch_language";
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import LanguageChange from '../../LanguageChange/LanguageChange';
import { Base64 } from 'js-base64';
import sessionRoute from '../../SessionRoute/sessionRoute';

//**** LOADER  */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: 'red',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);

function EmailSetUp() {
  //*** INITIALIZE VARIABLES */

  const classes = useStyles();
  const [intialLoader, setIntialLoader] = useState(false);
  const [codeflag, setCodeflag] = useState(false);
  const history = useHistory();
  // const dispatch = useDispatch()
  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_HOST_URL } = process.env;
  const [redirectUrl, setredirectUrl] = useState('');
  const [factorid, setFactorid] = useState('');
  const [stateToken1, setstateToken1] = useState('');
  const [data4, setData4] = useState({});
  const [timer, setTimer] = useState(false);
  const [counter, setCounter] = useState(30);
  const [mailId, setMailId] = useState('');
  const [userData, setUserData] = useState('');
  const [EnablePrevious, setEnablePrevious] = useState(false);
  const [OriginAllowed, setOriginAllowed] = useState(false);
  const [OriginDomain, setOriginDomain] = useState(true);

  const Data = useParams();
  const id = DOMPurify.sanitize(Data.id);
  const userId = DOMPurify.sanitize(Data.userId);

  //******** Getting Language Data From Redux  */

  const PageName = 'lbl_email';
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === 'default' &&
    LangData.LanguageData.LanguageData.filter(
      (item) => item.code === CodeLang && item.pageName === pageName
    ).length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === 'default'
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang && item.pageName === pageName
          )[0]?.data
        : {};
  }

  //** GETTING QUERY PARAMS DATA  */

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let stateToken = DOMPurify.sanitize(query.get('stateToken'));
  const Relay = DOMPurify.sanitize(query.get('Relay'));
  const LanguageCode = DOMPurify.sanitize(query.get('languageCode'));
  const RedirectURL = DOMPurify.sanitize(query.get('RedirectURL'));

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
        if(!RedirectURL){
        let data = res.data.appUrl.slice(0, -1);
        const FetchDomain = new URL(data).origin;
        TrustedRedirectUrl(FetchDomain);
        }
      })
      .catch((err) => console.log(err));
  };

  const TrustedRedirectUrl = async (domain) => {
    // setredirectUrl(domain);
    if (OriginDomain) {
      await axios
        .get(`${REACT_APP_API_ENDPOINT}origins/get-origins?domain=${domain}`)
        .then((res) => {
          if (res.data.length > 0) {
            res.data.map((originUrl) => {
              if (
                originUrl.origin === domain &&
                originUrl.status === 'ACTIVE'
              ) {
                setOriginAllowed(true);
              } else {
                setOriginAllowed(false);
              }
              return originUrl;
            });
          } else {
            setOriginAllowed(false);
            toast.error(langData?.lbl_email_setup_origin_allowed, {
              position: 'top-center',
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-center',
          });
        });
    }
  };

  useEffect(() => {
    if (
      RedirectURL &&
      langData?.lbl_email_setup_origin_allowed !== undefined
    ) {
      setOriginDomain(false);
      try {
        const FetchDomain = new URL(RedirectURL).origin;
        TrustedRedirectUrl(FetchDomain);
      } catch {
        redirect_location();
      }
    } 
    // else {
    //   if (langData?.lbl_create_account_origin_not_allow !== undefined) {
    //     redirect_location();
    //   }
    // }
    //eslint-disable-next-line
  }, [langData?.lbl_email_setup_origin_allowed]);

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    //  dispatch(fetchLanguageData(LanguageCode, id, PageName))
    EnrollEmail();
    //eslint-disable-next-line
  }, []);

  //*** ENROLL EMAIL FACTOR API */

  async function EnrollEmail() {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}factors/enroll-email-factor`, {
        stateToken: stateToken,
        factorType: 'email',
        provider: 'OKTA',
      })
      .then((res) => {
        if (res.data.status === 'MFA_ENROLL_ACTIVATE') {
          setFactorid(res.data._embedded.factor.id);
          setstateToken1(res.data.stateToken);
          setCodeflag(true);
          setTimer(true);
          setMailId(res.data._embedded.factor.profile.email);
          setUserData(res.data._embedded.user.profile);
          setEnablePrevious(true);
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session' && err?.response?.status === 401){
          toast.error(langData?.lbl_email_session_expire, {
            position: 'top-center',
          })
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

   //*** SET UP OTHER FACTORS PAGE  */

   const OtherFactors = (obj) => {
    history.push({
      pathname: `/otherfactorsrequire/${userId}/${id}`,
      search:  `?stateToken=${stateToken}&languageCode=${LanguageCode ? LanguageCode : 'en'}`,
      state: {factors: obj}
    }
    );
  };

  //*** ACTIVATE EMAIL API DATA   */

  async function ActivateEmail(passcode) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/activate-email-factor/${factorid}`,
        {
          stateToken: stateToken1,
          passCode: passcode,
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_ENROLL') {
          // if (id && LanguageCode) {
          //   let redirect = RedirectURL ? RedirectURL : redirectUrl
          //   window.location.href =
          //     redirect + (redirect.includes('?') ? `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` : `?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}`);
          // }
          let factors = res.data._embedded?.factors.filter(item => item.status === "NOT_SETUP" && item.enrollment === "REQUIRED")
          if(factors.length > 0){
              OtherFactors(res.data._embedded?.factors)
          }
        } else if (res.data.status === 'SUCCESS') {
          const sessionToken = res.data.sessionToken;
          if (sessionToken) {
            if (Relay) {
              window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + Relay}`;
            } else {
              if (OriginAllowed) {
                let redirect = RedirectURL ? RedirectURL : redirectUrl;
                let profileWithId = {
                  ...userData,
                  userId: res.data._embedded.user.id
               }
                const EncodedData = `SessionToken=${
                  res.data.sessionToken
                }&profile=${JSON.stringify(profileWithId)}`;
                const RedirectData = encodeURI(Base64.encode(EncodedData));
                window.location.href = redirect + ( redirect.includes('?') ? `&data=${RedirectData}` : `?data=${RedirectData}`);
              } else {
                toast.error(langData?.lbl_email_setup_origin_allowed, {
                  position: 'top-center',
                });
              }
            }
          }
        }
      })
      .catch((err) => {
        if(err?.response?.data?.errorCode === 'invalid_session'){
          toast.error(langData?.lbl_email_session_expire, {
            position: 'top-center',
          })
        }else{
          toast.error(err?.response?.data?.errorSummary, {
            position: 'top-center',
          });
        }
      });
  }

  //***** TIMER  */

  useEffect(() => {
    if (timer) {
      if (counter === 0) {
        setTimer(false);
      } else {
        const Timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(Timer);
      }
    }
  }, [counter, timer]);

  //*** FORMIK VALIDATION */

  const formik1 = useFormik({
    initialValues: {
      Code: '',
    },
    validationSchema: yup.object({
      Code: yup.number().required(langData.lbl_email_setup_code_required),
    }),
    onSubmit: (userInputData) => {
      ActivateEmail(userInputData.Code);
    },
  });

  //**** BACK TO FACTORS PAGE API */

  const PreviousFactors = async () => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/login/previous`, {
        stateToken: stateToken1,
      })
      .then((res) => {
        if (res.data.stateToken) {
          if (Relay) {
            history.push(
              `/multifactor/${userId}/${id}?stateToken=${res.data.stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&Show=true`
            );
          } else {
            if (OriginAllowed) {
              history.push(
                `/multifactor/${userId}/${id}?stateToken=${
                  res.data.stateToken
                }&RedirectURL=${
                  RedirectURL ? RedirectURL : redirectUrl
                }&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&Show=true`
              );
            } else {
              toast.error(langData?.lbl_email_setup_origin_allowed, {
                position: 'top-center',
              });
            }
          }
        }
      });
  };

  //**** BACK TO FACTORS SUBMIT  */
  const BacktoFactors = () => {
    EnablePrevious
      ? PreviousFactors()
      : history.push(
          `/multifactor/${userId}/${id}?stateToken=${stateToken}&Relay=${Relay}&languageCode=${LanguageCode ? LanguageCode : 'en'}&Show=true`
        );
  };

  //********** RESEND EMAIL LINK API*/

  async function EnrollResendEmail() {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}factors/enroll-resend-email-otp/${factorid}`,
        {
          stateToken: stateToken,
          factorType: 'email',
          provider: 'OKTA',
        }
      )
      .then((res) => {
        if (res.data.status === 'MFA_ENROLL_ACTIVATE') {
          setFactorid(res.data._embedded.factor.id);
          setstateToken1(res.data.stateToken);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.errorSummary, {
          position: 'top-center',
        });
      });
  }

  //*** SUBMIT RESEND EMAIL */

  const handleResend = () => {
    if (counter === 0) {
      EnrollResendEmail();
    }
    setTimer(true);
    setCounter(30);
  };

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    redirect_location();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const Header_Color = data4.custom_properties?.headerColor;
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: '#fff' }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={id}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : 'en'}
            />
            <div
              className="main-bg configure-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" onClick={() => sessionRoute(redirectUrl)} />
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ color: Header_Color, fontFamily: fontFamily }}
                  >
                    {langData.lbl_email_setup_factor}
                  </h2>
                </div>
                <div className="wrapper-inner">
                  {codeflag ? (
                    <>
                      <p>
                        {langData.lbl_email_setup_otp_setn} {mailId}
                      </p>
                      <div
                        className={
                          formik1.touched.Code && formik1.errors.Code
                            ? 'form-group has-danger'
                            : 'form-group'
                        }
                      >
                        <input
                          className="custom-input"
                          type="tel"
                          name="Code"
                          value={formik1.values.Code}
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                          style={{ color: Input_color }}
                          required
                        />
                        <label className="custom-label">
                          {langData.lbl_email_setup_enter_code}
                        </label>
                        {formik1.touched.Code && formik1.errors.Code ? (
                          <div className="error-msg">{formik1.errors.Code}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-yellow btn-block"
                          onClick={formik1.handleSubmit}
                          type="button"
                          style={{
                            background: SignIn_button_backgroundColor,
                            color: SignIn_button_color,
                          }}
                        >
                          {langData.lbl_email_setup_activate_mail}
                        </button>
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-yellow btn-block"
                          onClick={handleResend}
                          disabled={timer}
                          style={{ background: SignIn_button_backgroundColor }}
                        >
                          {langData.lbl_email_setup_resend_email}
                        </button>
                        {timer ? (
                          <>
                            <div className="error-msg">
                              {langData.lbl_email_setup_retry} {counter}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}

                  <div className="form-group">
                    <button
                      className="btn btn-yellow-brd btn-block"
                      type="button"
                      onClick={BacktoFactors}
                      style={{ background: SecondaryButtonColor }}
                    >
                      {langData.lbl_email_setup_back_factor}
                    </button>
                  </div>
                  <div className="form-group text-center mb-0">
                    <p className="create-account text-decoration-none btn-text">
                      {langData.lbl_email_setup_safe_secure}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EmailSetUp;
