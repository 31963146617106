import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { Field, Form, Formik } from "formik";
import "../../global1.css";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import { Backdrop, IconButton } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";
import {Visibility, VisibilityOff} from '@mui/icons-material'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


//********** LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: "100%",
    },
  })
);

/******  CHANGING FAVICON DYNAMICALLY  */

function getFaviconEl() {
  return document.getElementById('favicon');
}
function getAppTitle() {
  return document.getElementById('AppTitle');
}

function Changepassword() {

  //***** INITIALIZE VARIABELS */

  const { REACT_APP_API_ENDPOINT, REACT_APP_API_ENDPOINT_SECURE} = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const [data4, setData4] = useState({});
  const [redirectUrl, setredirectUrl] = useState("");
  const [userData,setuserData] = useState("")
  // const dispatch = useDispatch()
  // PASSWORD COMPLEXITY
  const [passwordComplexity, setPaswordComplexity] = useState({});
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [disableFields, setDisableFields] = useState(false)

 //*****  FETCHING DATA FROM QUERY PARAMETERS */
  const Data = useParams()
  const id = DOMPurify.sanitize(Data.id);
  const user_id = DOMPurify.sanitize(Data.user_id);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let token = DOMPurify.sanitize(query.get("token"));
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"));
  

  const  { executeRecaptcha }  = useGoogleReCaptcha()

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.appUrl);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);


//******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
        passwordPolicies(res.data.appBasicDetails?.oktaGroupID)
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  
 

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
   const Header_Color = data4.custom_properties?.headerColor 
   const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor
   const AppTitle = data4.appBasicDetails?.appName;


  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = "lbl_change_password";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (defaultLang === "default" && LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0) {
    langData = LangData.LanguageData.LanguageData.filter((item) => item.code === "default")[0]?.data;
  } else {
    langData =LangData.LanguageData.LanguageData.length > 0 ? LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data: {};
  }

  // useEffect(() => {
  //   dispatch(fetchLanguageData(LanguageCode, id, PageName));
  //   //eslint-disable-next-line
  // }, []);

  //***********  GETTING DYNAMIC USER DATA FROM OKTA */

  async function User_details() {
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT_SECURE}get-dynamic-user-detail/${user_id}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoader(false);
        let Obj = {}
        Object.keys(res.data.profile).forEach((key) => {
          Obj={...Obj,[key]:res.data.profile[key]}
        }
        );
        setuserData(Obj);
      }).catch( err => {
        setLoader(false)
        if( err.response.data.messgae === "Unauthorised Access"){
          setDisableFields(true)
          toast.error(err.response.data.messgae, {position:'top-center'})
        }
      });
  }

  useEffect(() => {
    User_details()
    //eslint-disable-next-line
  },[])

  

  
  //**** CHANGE PASSWORD API */

  const Changepassword = async (
    currentPassword,
    newPassword,
    confirmPassword,
    reCaptchaToken
  ) => {
    setLoader(true);
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT_SECURE}change-password/${user_id}/${id}`,
        {
          oldPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          reCaptchaToken: reCaptchaToken
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        if (res.data.responseStatus === "SUCCESS") {
          toast.success(langData.lbl_change_password_success, {
            position: "top-center",
          });
            window.location.href = redirectUrl + "?SignOutFlag=true";
        } else {
          const ErrorData = res.data.errorCode !== 'invalid-captcha' ?  JSON.parse(res.data.messgae) : '';
          if(res.data.errorCode === 'invalid-captcha') {
            toast.error(res.data.messgae, {
              position: "top-center",
            });
          }else if (ErrorData.errorCauses.length > 0) {
            toast.error(ErrorData.errorCauses[0].errorSummary, {
              position: "top-center",
            });
          }else {
            toast.error(res.data.messgae, {
              position: "top-center",
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(langData.lbl_change_password_failed, {
          position: "top-center",
        });
      });
  };

  // ************ FORM VALIDATION FORMIK *********

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .required(langData.lbl_change_password_old_password_required),
    newPassword: yup
      .string()
      .required(langData.lbl_change_password_new_password_required)
      .min(
        passwordComplexity.minLength,
        langData['lbl_change_password_min_password_length']?.replace("{0}", passwordComplexity.minLength)
      )
      .test("minNumber", langData.lbl_change_password_one_number_required, (value) => {
        if (value && passwordComplexity.minNumber > 0) {
          const regex = /\d/g;
          let number = value.match(regex);
          if (number && number.length >= passwordComplexity.minNumber) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minLowercase", langData.lbl_change_password_one_lowercase_required, (value) => {
        if (value && passwordComplexity.minLowerCase > 0) {
          const regex = /[a-z]/g;
          let text = value.match(regex);
          if (text && text.length >= passwordComplexity.minLowerCase) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minUppercase", langData.lbl_change_password_one_uppercase_required, (value) => {
        if (value && passwordComplexity.minUpperCase > 0) {
          let text = value.match(/[A-Z]/g);
          if (text && text.length >= passwordComplexity.minUpperCase) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minSymbol", langData.lbl_change_password_one_symbol_required, (value) => {
        if (value && passwordComplexity.minSymbol > 0) {
          let text = value.match(/[^A-Z0-9a-z]/g);
          if (text && text.length >= passwordComplexity.minSymbol) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }).test("no_Username", langData.lbl_change_password_no_username, (value) => {
        let Email = userData?.email;
          if(value && passwordComplexity.excludeUsername){
            if(Email !== ""){
              // const delimiters = ['.', ',', '-', '_', '#', '@'];
              const loginParts = Email?.split(/[@.\-_#,]/).filter(part => part.length >=3);
              const lowercaseLoginParts = loginParts.map(part => part.toLowerCase());
              const passwordLowerCase = value.toLowerCase();
              let userName_Flag = true
                  for (const loginPart of lowercaseLoginParts) {
                    if(passwordLowerCase.includes(loginPart)){
                       userName_Flag = false;
                       break;
                    }
                  }
                  return userName_Flag
            }else{
              return true
            }
          }
        return true
      }).test( "no_Firstname", langData.lbl_change_password_no_first_name, (value) => {
        let Firstname = userData.firstName
         if(value && passwordComplexity?.excludeAttributes?.length > 0 && passwordComplexity.excludeAttributes.indexOf("firstName") !== -1){
           if(userData.firstName !== ""){
              if(Firstname.length >=3 && value?.toLowerCase().includes(Firstname?.toLowerCase())){
                return false
              }
              else{
                return true
              }
           }else{
             return true
           } 
         }
         return true
      }).test( "no_Lastname", langData.lbl_change_password_no_last_name, (value) => {
        if(value && passwordComplexity?.excludeAttributes?.length > 0 && passwordComplexity.excludeAttributes.indexOf("lastName") !== -1){
          if(userData.lastName !== ""){
             if(userData.lastName.length >=3 && value?.toLowerCase()?.includes(userData?.lastName?.toLowerCase())){
               return false
             }else{
               
               return true
             }
          }else{
            return true
          } 
        }
        return true
     }).test("Exclude_Spaces", langData?.lbl_change_password_password_without_space, (val) => {
      if((id === '163142638239744' || id === '915041708670976' || id === "824955038072832") && val){
        let regex = /^[^\s]+$/g;
        let Data = val.match(regex);
        if (Data) {
          return true;
        } else {
          return false;
        }
      }else{
        return true;
      }
    }),
    confirmPassword: yup
      .string()
      .required(langData.lbl_change_password_cpassword_required)
      .oneOf(
        [yup.ref("newPassword"), null],
        langData.lbl_change_password_password_cpassword_not_match
      ),
  });

  //***** SUBMIT CHANGE PASSWORD API */

  const onSubmit = async (values, props) => {
    let reCaptcha_token = ""
    if(data4.appBasicDetails?.enableGoogleCaptcha){
      if (!executeRecaptcha) {
       toast.error(langData?.lbl_change_password_captcha_not_available, {
         position: 'top-center'
       })
       return;
     }
     reCaptcha_token = await executeRecaptcha("");
   }else{
    reCaptcha_token = ""
   }
    Changepassword(
      values.currentPassword,
      values.newPassword,
      values.confirmPassword,
      reCaptcha_token
    );
    setTimeout(() => {
      props.resetForm();
    }, 1000);
  };

  //**** GETTING PASSWORD POLICIES */

  const passwordPolicies = async (GroupId) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}policies/get-password-policies`)
      .then((res) => {
        const policyObject = res.data.filter((item) => {
          const groups = item.conditions.people.groups.include;
          if (
            groups.filter((groupId) => groupId === GroupId).length > 0
          ) {
            return true;
          }
          return false;
        });
        if (policyObject.length > 0) {
          setPaswordComplexity(policyObject[0].settings.password.complexity);
        }else{
          setPaswordComplexity(res.data[res.data.length - 1].settings.password.complexity)
         }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //*** BACK TO APPLICATION HOME */

  const BackHome = () => {
    window.location.href = redirectUrl;
  };

//*********    FAVICON DYNAMIC CHANGE */

const handlefavicon = () => {
  const favicon = getFaviconEl(); // Accessing favicon element
  if (logo) {
    favicon.href = logo;
  }
};
const handleAppTitle = () => {
  const Title = getAppTitle();
  if (AppTitle) {
    Title.innerText = AppTitle;
  }
};

useEffect(() => {
  handlefavicon();
  handleAppTitle();
  //eslint-disable-next-line
}, [logo]);

function captchaVisibility(){
  document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
}
  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'} />
            <div
              className="main-bg change-password-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div>
                  {loader ? (
                    <Backdrop
                      className={classes.root}
                      sx={{ color: "#fff" }}
                      open
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : null}
                </div>
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo} alt="Pepsi Micdrop Logo" />
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {langData.lbl_change_password}
                  </h2>
                </div>
                <div className="wrapper-inner">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {(props) => (
                      <Form className="mb-0">
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.currentPassword &&
                                props.errors.currentPassword
                                  ? "change-password-sec form-group has-danger"
                                  : "change-password-sec form-group"
                              }
                            >
                              <input
                                className="custom-input"
                                type={ showCurrentPassword ? "text" : "password"}
                                name="currentPassword"
                                id="currentPassword"
                                value={props.values.currentPassword}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{color: Input_color}}
                                aria-label="currentPassword"
                                required
                                disabled={disableFields ? true : false}
                              />
                              <label className="custom-label" htmlFor="currentPassword">
                                {langData.lbl_change_password_current_password +
                                  "*"}
                              </label>
                              <IconButton
                                  className="change-password-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowCurrentPassword( previousState => !previousState)
                                  }}
                                  aria-label="Show/Hide Password Icon"
                                  >
                                  {showCurrentPassword ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                              {props.touched.currentPassword &&
                              props.errors.currentPassword ? (
                                <div className="sign-in-sec error-msg" aria-label={props.errors.currentPassword}>
                                  {props.errors.currentPassword}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                        <div className="form-group line-height-0 font-size-0">
                          <span className="brd-btm"></span>
                        </div>
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.newPassword &&
                                props.errors.newPassword
                                  ? "change-password-sec form-group has-danger"
                                  : "change-password-sec form-group "
                              }
                            >
                              <input
                                className="custom-input"
                                type={ showPassword ? "text" : "password"}
                                name="newPassword"
                                id="newPassword"
                                value={props.values.newPassword}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{color: Input_color}}
                                required
                                aria-label="newPassword"
                                disabled={disableFields ? true : false}
                              />
                              <label className="custom-label" htmlFor="newPassword">
                                {langData.lbl_change_password_new_password +
                                  "*"}
                              </label>
                              <IconButton
                                  className="change-password-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowPassword( previousState => !previousState)
                                  }}
                                  aria-label="Show/Hide Password Icon"
                                  >
                                  {showPassword ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                              {props.touched.newPassword &&
                              props.errors.newPassword ? (
                                <div className="sign-in-sec error-msg" aria-label={props.errors.newPassword}>
                                  {props.errors.newPassword}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.confirmPassword &&
                                props.errors.confirmPassword
                                  ? "change-password-sec form-group has-danger"
                                  : "change-password-sec form-group "
                              }
                            >
                              <input
                                className="custom-input"
                                type={ showPassword1 ? "text" : "password"}
                                name="confirmPassword"
                                id="confirmPassword"
                                value={props.values.confirmPassword}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{color: Input_color}}
                                aria-label="confirmPassword"
                                required
                                disabled={disableFields ? true : false}
                              />
                              <label className="custom-label">
                                {langData.lbl_change_password_confirm_password +
                                  "*"}
                              </label>
                              <IconButton
                                  className="change-password-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowPassword1( previousState => !previousState)
                                  }}
                                  aria-label="Show/Hide Password Icon"
                                  >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                              {props.touched.confirmPassword &&
                              props.errors.confirmPassword ? (
                                <div className="sign-in-sec error-msg" aria-label={props.errors.confirmPassword}>
                                  {props.errors.confirmPassword}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                        <div className="pwd-requirement">
                          <p className="font-weight-bold">
                            {langData.lbl_change_password_requirements}
                          </p>
                          <ul className="pl-4 mb-0">
                            {passwordComplexity.minLength > 0 ? (
                              <li>
                                {langData.lbl_change_password_minumum_char?.replace(
                                  "{0}",
                                  passwordComplexity.minLength
                                )}
                              </li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.minLowerCase > 0 ? (
                              <li>{langData.lbl_change_password_lower_char}</li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.minUpperCase > 0 ? (
                              <li>{langData.lbl_change_password_upper_char}</li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.minNumber > 0 ? (
                              <li>{langData.lbl_change_password_number}</li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.minSymbol > 0 ? (
                              <li>{langData.lbl_change_password_symbol}</li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.excludeUsername ? (
                              <li>
                                {langData.lbl_change_password_no_username}
                              </li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.excludeAttributes &&
                            passwordComplexity.excludeAttributes.filter(
                              (item) => item === "firstName"
                            ).length > 0 ? (
                              <li>{langData.lbl_change_password_no_first_name}</li>
                            ) : (
                              <></>
                            )}
                            {passwordComplexity.excludeAttributes &&
                            passwordComplexity.excludeAttributes.filter(
                              (item) => item === "lastName"
                            ).length > 0 ? (
                              <li>{langData.lbl_change_password_no_last_name}</li>
                            ) : (
                              <></>
                            )}
                            {
                                id === '163142638239744' || id === '915041708670976' || id === "824955038072832" ? 
                                <>
                                <li>
                                  do not include spaces in Password
                                </li>
                                </> :
                                <>
                                </>
                              }
                          </ul>
                        </div>

                        <div className="form-group">
                          <button type="submit" onClick={props.handleSubmit} className="btn btn-yellow btn-block " style={{background: SignIn_button_backgroundColor}} aria-label={langData.lbl_change_password} disabled={disableFields ? true : false}>
                            {langData.lbl_change_password}
                          </button>
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-yellow-brd btn-block"
                            onClick={BackHome}
                            style={{background: SecondaryButtonColor}}
                            aria-label={langData.lbl_change_password_cancel}
                            type="button"
                          >
                            {langData.lbl_change_password_cancel}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Changepassword;
