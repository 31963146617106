import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../global1.css";
import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router";
import { Backdrop, IconButton } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";

import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import { OktaAuth } from "@okta/okta-auth-js";
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import sessionRoute from "../SessionRoute/sessionRoute";
import { Base64 } from "js-base64";


//**********  LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      height: "100%",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
    },
  })
);

//******  CHANGING FAVICON DYNAMICALLY  */

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppTitle() {
  return document.getElementById("AppTitle");
}

const Form1 = () => {
  //********* INITIAL VARIABLES */
  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_HOST_URL} = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const history = useHistory();
  const [helpshow, sethelpShow] = useState(false);
  const [data4, setData4] = useState({});
  // const dispatch = useDispatch();
  const [DeviceTokenLifeTime, setDeviceTokenLifeTime] = useState(
    45 * 3600 * 24
  );
  const[appBasicDetails, setappBasicDetails] = useState({})
  const [userId, setUserId] = useState("");
  const [StagedUser, setStagedUser] = useState(false);
  const [UserStaged, setUserStaged] = useState(false)
  const [Timer, setTimer] = useState(false);
  const [redirectUrl, setredirectUrl] = useState("");
  const [otherHelpLink, setOtherHelpLink]= useState("")
  const [EnableDropDown, setEnableDropDown] = useState(false)
  const captchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false)
  const[firstTimeUser,setFirstTimeUser] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  

  //*****  FETCHING DATA FROM QUERY PARAMETERS */

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let LoginHintData = DOMPurify.sanitize(query.get("LoginHint"));
  const data1 = decodeURIComponent(LoginHintData);
  const parsedData = JSON.parse(data1);
  const appId = parsedData.AppId;
  const LanguageCode = parsedData.languageCode;
  let Relaystate = query.get("RelayState");
  const decoded_relay = decodeURIComponent(Relaystate);

  //***** REDIRECT URL   ******** */
  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${appId}`)
      .then((res) => {
        setredirectUrl(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl);
        setOtherHelpLink(res.data?.otherIssueURL ? res.data.otherIssueURL : "")
        setEnableDropDown(res.data.otherIssue)
      })
      .catch((err) => console.log(err));
  };

  //******* FETCHING DATA FROM RENDERING SERVICE */
  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${appId}`)
      .then((res) => {
        setData4(res.data);
        setappBasicDetails(res.data.appBasicDetails)
        setIntialLoader(false);
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    redirect_location();
    //eslint-disable-next-line
  }, []);

  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */

  const forgotPassword_Color = data4.custom_properties?.forgotPasswordColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =
    data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const Header_Color = data4.custom_properties?.headerColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const AppTitle = data4.appBasicDetails?.appName;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = "lbl_login";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (
    defaultLang === "default" &&
    LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang)
      .length === 0
  ) {
    langData = LangData.LanguageData.LanguageData.filter(
      (item) => item.code === "default"
    )[0]?.data;
  } else {
    langData =
      LangData.LanguageData.LanguageData.length > 0
        ? LangData.LanguageData.LanguageData.filter(
            (item) => item.code === CodeLang
          )[0]?.data
        : {};
  }
  // useEffect(() => {
  //   dispatch(fetchLanguageData(LanguageCode, appId, PageName));
  //   //eslint-disable-next-line
  // }, []);

  //****** FORMIK FORM VALIDATION  */

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(langData.lbl_login_required_email)
      .email(langData.lbl_login_invalid_email),
    password: yup.string().required(langData.lbl_login_invalid_password),
  });

  const onSubmit = async (values, props) => {
    let reCaptcha_token = ""
    if(data4.appBasicDetails?.enableGoogleCaptcha){
      if (!executeRecaptcha) {
       toast.error(langData?.lbl_login_captcha_not_available, {
         position: 'top-center'
       })
       return;
     }
     reCaptcha_token = await executeRecaptcha("");
   }else{
    reCaptcha_token = ""
   }
    if (localStorage.getItem("FactorLifeTime")) {
      setDeviceTokenLifeTime(localStorage.getItem("FactorLifeTime"));
    }

    login(values.email, values.password, props, reCaptcha_token);
  };

  //*****  LOGIN API ASYNC CALL */
  axios.defaults.withCredentials = true;
  async function login(email, password, props, reCaptcha_token) {
    let reCaptchaTokenCall = ''
    reCaptchaTokenCall = await executeRecaptcha()
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-user-via-email/${appId}?email=${encodeURIComponent(email)}&reCaptchaToken=${reCaptchaTokenCall}`)
      .then((res) => {
        setLoader(false);
        if(res.data.responseStatus === "SUCCESS"){
            if (res.data && res.data.status === "PROVISIONED") {
              setStagedUser(true);
              toast.info(
                langData?.lbl_login_please_reactivate_account,
                {
                  position: "top-center",
                }
              );
              setUserId(res.data.id);
            }
            else if (res.data && res.data.status === "STAGED") {
              setStagedUser(true);
              setUserStaged(true)
              toast.info(
                langData?.lbl_login_please_activate_account,
                {
                  position: "top-center",
                }
              );
              setUserId(res.data.id);
            }  
            else {
              //**** STARTING */
              setLoader(true);
              axios
                .post(
                  `${REACT_APP_API_ENDPOINT}login/${appId}`,
                  {
                    username: email,
                    password: password,
                    tokenTimeInSeconds: DeviceTokenLifeTime,
                    reCaptchaToken: reCaptcha_token
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((res) => {
                  setLoader(false);
                  const stateToken = res.data.stateToken;
                  if(res.data.responseStatus === 'SUCCESS'){
                    if (res.data.status === "MFA_ENROLL") {
                      const user_id = res.data._embedded.user.id;
                      setTimeout(() => {
                        props.resetForm();
                      }, 1000);
                      history.push(
                        `/multifactor/${user_id}/${appId}?stateToken=${stateToken}&Relay=${decoded_relay}&languageCode=${
                          LanguageCode ? LanguageCode : "en"
                        }&Show=false`
                      );
                    } else if (res.data.status === "MFA_REQUIRED") {
                      setTimeout(() => {
                        props.resetForm();
                      }, 1000);
                      const userId = res.data._embedded.user.id;
                      const FactorLifeTime =
                        res.data._embedded.policy.rememberDeviceLifetimeInMinutes;
                      localStorage.setItem("FactorLifeTime", FactorLifeTime);
                      history.push(
                        `/passcode/${userId}/${appId}?stateToken=${stateToken}&Relay=${decoded_relay}&languageCode=${
                          LanguageCode ? LanguageCode : "en"
                        }&activate=false`
                      );
                    } else if (res.data.status === "LOCKED_OUT") {
                      toast.error(langData.lbl_login_account_lock, {
                        position: "top-center",
                      });
                    } else if (res.data.status === "SUCCESS") {
                      setTimeout(() => {
                        props.resetForm();
                      }, 1000);
                      const sessionToken = res.data.sessionToken;
                      window.location.href = `${REACT_APP_OKTA_HOST_URL}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${REACT_APP_OKTA_HOST_URL + decoded_relay}`;
                    }
                  }else{
                    if(res.data.errorCode === 'invalid-captcha'){
                      toast.error( res.data.messgae, {
                        position: 'top-center'
                      })
                    }else if(res.data.errorCode === "invalid-cred"){
                      toast.error(langData.lbl_login_incorrect_cred, {
                        position: "top-center",
                      })
                    }
                  }
                })
                .catch((err) => {
                  setLoader(false);
                  toast.error(langData.lbl_login_incorrect_cred, {
                    position: "top-center",
                  });
                });

              //**Ending */
            }
        }else{
           toast.error(langData?.lbl_login_activation_faild,{
            position: "top-center"
           })
        }
      }).catch(err =>{
        setLoader(false)
        toast.error(langData?.lbl_login_try_again,{
          position:'top-center'
        })
      });
  }

  //*******  SOCIAL GOOGLE LOGIN BUTTON */
  const history1 = useHistory();

  const onAuthRequired = () => {
    history1.push("/home");
  };

  const RouteGoogle = async (IDP) => {
    const oktaAuthSso = new OktaAuth({
      issuer: data4.oktaConfigDetails?.issuer,
      clientId: data4.oktaConfigDetails?.clientID,
      redirectUri: data4.oktaConfigDetails?.redirectURI,
      onAuthRequired: onAuthRequired,
      pkce: true,
    });
  let socialIDP = ''
  if(IDP === 'google'){
     socialIDP = data4.oktaConfigDetails?.googleIDP
  }else if(IDP === 'facebook'){
    socialIDP = data4.oktaConfigDetails?.facebookIDP
  }else if(IDP === 'apple'){
    socialIDP = data4.oktaConfigDetails?.appleIDP
  }
    oktaAuthSso.signInWithRedirect({
      idp: socialIDP,
      responseType: "code",
      originalUri: "/",
      scopes: ["openid", "offline_access"],
      redirectUri: data4.oktaConfigDetails?.redirectURI,
    });
  };

  //********  HELP SECTION CODE */

  const ToggleHelpSection = () => {
    sethelpShow(!helpshow);
  };

  //*********    FAVICON DYNAMIC CHANGE */

  const handlefavicon = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (logo) {
      favicon.href = logo;
    }
  };
  const handleAppTitle = () => {
    const Title = getAppTitle();
    if (AppTitle) {
      Title.innerText = AppTitle + " Sign-in ";
    }
  };

  useEffect(() => {
    handlefavicon();
    handleAppTitle();
    //eslint-disable-next-line
  }, [logo]);

  const StagedUserActivate = async (token) => {
    setLoader(true)
    await axios.post(`${REACT_APP_API_ENDPOINT}activate-stage-user/${Base64.encode(userId)}/${appId}?redirect=${redirectUrl}&langCode=${LanguageCode}&token=${token}`)
    .then( res => {
      setLoader(false)
      if(res.data && res.data.responseStatus === "SUCCESS"){
        toast.success(langData?.lbl_login_activation_mail_success,{
          position: 'top-center'
        })
        setStagedUser(false)
      }else{
         if(res.data.errorCode === 'invalid-captcha'){
           toast.error( res.data.messgae, {
            position: 'top-center'
           })
         }
      }
    }).catch(err => {
      setLoader(false)
      if(appBasicDetails?.enableGoogleCaptcha){
        captchaRef.current.reset()
      }
      toast.error( langData?.lbl_login_error, {
        position: "top-center"
      })
    })
  }

  const ReactivateAccount = async (token) => {
        setTimer(true);
        setTimeout(() => {
          setTimer(false);
        }, 30000);
        setLoader(true);
        await axios
          .post(
            `${REACT_APP_API_ENDPOINT}reactivate-user/${Base64.encode(userId)}/${appId}?redirect=${redirectUrl}&langCode=${
              LanguageCode ? LanguageCode : "en"
            }&token=${token}`
          )
          .then((res) => {
            setLoader(false);
            if(res.data.responseStatus === 'SUCCESS'){
              if (res.data.status === "PROVISIONED") {
                toast.success(langData?.lbl_login_resend_activation_mail_sent, {
                  position: "top-center",
                });
              }
            }else{
              if(res.data.errorCode === 'invalid-captcha'){
                toast.error( res.data.messgae, {
                  position: 'top-center'
                })
              }
            }
            
          })
          .catch((err) => {
            setLoader(false);
            if(appBasicDetails?.enableGoogleCaptcha){
              captchaRef.current.reset()
            }
            if (err.response.data.errorCode === "E0000038") {
              setStagedUser(false);
              toast.error(langData?.lbl_login_user_already_active, {
                position: "top-center",
              });
            } else {
              toast.error(err.response.data.errorSummary, {
                position: "top-center",
              });
            }
          });
  };

  const GoogleVerify_ReactivateAccount = async () => {
    let token = ""
    if(appBasicDetails?.enableGoogleCaptcha){
      token = await executeRecaptcha();
    }
    if(appBasicDetails?.enableGoogleCaptcha && token){
      UserStaged ? StagedUserActivate(token) : ReactivateAccount(token);
    }else{
      if(appBasicDetails?.enableGoogleCaptcha){
        toast.error(langData?.lbl_login_verify_captcha,{
          position:"top-center"
        })
      }else{
        UserStaged ? StagedUserActivate(token) : ReactivateAccount(token);
      }
    }
    
    
          
  };

  const handleFirstTimeUser = () => {
     setFirstTimeUser(true)
     
  }

  useEffect(() => {
    if(firstTimeUser){
       toast.info(langData?.lbl_login_email_password_sign_in,{
        position:'top-center'
       })
       setFirstTimeUser(false)
    }
    //eslint-disable-next-line
  },[firstTimeUser])


  const BackTOSignIn = () => {
    setStagedUser(false);
  };

  function captchaVisibility(){
    document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
  }

  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
            <LanguageChange
              ConsumerAppId={appId}
              PageName={PageName}
              Lang={LanguageCode ? LanguageCode : "en"}
            />
            <div>
              {loader ? (
                <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : null}
            </div>
            <div
              className="main-bg sign-in-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img
                      src={logo ? logo : DefaultLogo}
                      alt="Pepsi Micdrop Logo"
                      onClick={() => sessionRoute(data4?.appBasicDetails?.appRedirectionURL)}
                    />
                  </div>
                  <h1
                    className="snacks-title"
                    style={{
                      color: Header_Color,
                      fontFamily: fontFamily,
                    }}
                  >
                    {" "}
                    {StagedUser
                      ? langData?.lbl_login_reactivate_account
                      : langData?.lbl_login_sign_in}
                  </h1>
                </div>
                <div className=" wrapper-inner" >
                  {StagedUser ? null : (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      
                    >
                      {(props) => (
                        <Form className="mb-0" >
                          <Field>
                            {() => (
                              <div
                                className={
                                  props.touched.email && props.errors.email
                                    ? "sign-in-sec form-group has-danger"
                                    : "sign-in-sec form-group"
                                }
                              >
                                <input
                                  className="custom-input"
                                  type="text"
                                  name="email"
                                  id="email"
                                  value={props.values.email}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  style={{ color: Input_color }}
                                  required
                                  aria-label={langData?.lbl_login_email}
                                  aria-invalid = {props.touched.email &&  props.errors.email ? true : false}
                                />
                                <label className="custom-label" htmlFor="email">
                                  {langData?.lbl_login_email + "*"}
                                </label>
                                {props.touched.email && props.errors.email ? (
                                  <div className="sign-in-sec error-msg" aria-label={props.errors.email}>
                                    {props.errors.email}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Field>
                          <Field>
                            {() => (
                              <div
                                className={
                                  props.touched.password &&
                                  props.errors.password
                                    ? " form-group has-danger"
                                    : "form-group"
                                }
                              >
                                <input
                                  className="custom-input"
                                  type={ showPassword ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  style={{ color: Input_color }}
                                  required
                                  aria-label={langData?.lbl_login_password}
                                  aria-invalid={props.touched.password &&  props.errors.password ? true : false}
                                />
                                <label className="custom-label" htmlFor="password">
                                  {langData?.lbl_login_password + "*"}
                                </label>
                                <IconButton
                                  className="sign-in-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowPassword( previousState => !previousState)
                                  }}
                                  aria-label="Show/hide password text"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                                {props.touched.password &&
                                props.errors.password ? (
                                  <div className="error-msg" aria-label={props.errors.password}>
                                    {props.errors.password}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Field>
                          {/* <div className="form-group d-flex justify-content-between">
                            <div>
                              <div
                                tabIndex={0}
                                className="d-flex justify-content-start align-items-center m-0"
                                onClick={ToggleHelpSection}
                                onKeyPress={(event) =>
                                  event.key === "Enter" && ToggleHelpSection()
                                }
                              >
                                <span className="m-0">
                                  {" "}
                                  {helpshow ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropDownIcon
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  )}
                                </span>
                                <span
                                  className="sign-in-sec btn-text"
                                  style={{
                                    color: forgotPassword_Color,
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                >
                                  {langData.lbl_login_need_help}
                                </span>
                              </div>
                              <div style={{ marginLeft: "35px" }}>
                                <Link
                                  to={`/forgotpassword/${appId}?languageCode=${
                                    LanguageCode ? LanguageCode : "en"
                                  }`}
                                  className={
                                    helpshow
                                      ? "sign-in-sec needhelpshow btn-text"
                                      : "sign-in-sec needhelpclose btn-text"
                                  }
                                  style={{
                                    color: forgotPassword_Color,
                                    textDecoration: "none",
                                  }}
                                >
                                  {langData.lbl_login_forgot_password}
                                </Link>
                              </div>
                              <div style={{ marginLeft: "35px" }}>
                                <Link
                                  to={`/forgotpassword/${appId}?languageCode=${
                                    LanguageCode ? LanguageCode : "en"
                                  }`}
                                  className={
                                    helpshow
                                      ? "sign-in-sec needhelpshow btn-text"
                                      : "sign-in-sec needhelpclose btn-text"
                                  }
                                  style={{
                                    color: forgotPassword_Color,
                                    textDecoration: "none",
                                  }}
                                >
                                  {langData.lbl_login_other_issues}
                                </Link>
                              </div>
                            </div>
                          </div> */}

                          <div className="form-group">
                            <button
                              className="btn btn-yellow btn-block"
                              style={{
                                background: SignIn_button_backgroundColor,
                              }}
                              aria-label={langData?.lbl_login_sign_in}
                            >
                              {langData?.lbl_login_sign_in}
                            </button>
                            
                          {
                            EnableDropDown ? 
                            <div className="form-group d-flex justify-content-between mt-2" >
                              <div>
                                <div
                                  tabIndex={0}
                                  className="d-flex justify-content-start align-items-center m-0"
                                  onClick={ToggleHelpSection}
                                  onKeyPress={(event) =>
                                    event.key === "Enter" && ToggleHelpSection()
                                  }
                                >
                                  <span className="m-0">
                                    {" "}
                                    {helpshow ? (
                                      <ArrowDropDownIcon />
                                    ) : (
                                      <ArrowDropDownIcon
                                        style={{ transform: "rotate(270deg)" }}
                                      />
                                    )}
                                  </span>
                                  <span
                                    className="sign-in-sec btn-text"
                                    style={{
                                      color: forgotPassword_Color,
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    aria-label={langData?.lbl_login_need_help}
                                  >
                                    {langData?.lbl_login_need_help}
                                  </span>
                                </div>
                                <div style={{ margin: "5px 25px" }}>
                                  <Link
                                    to={`/forgotpassword/${appId}?languageCode=${
                                      LanguageCode ? LanguageCode : "en"
                                    }`}
                                    className={
                                      helpshow
                                        ? "sign-in-sec needhelpshow btn-text"
                                        : "sign-in-sec needhelpclose btn-text"
                                    }
                                    style={{
                                      color: forgotPassword_Color,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {langData.lbl_login_forgot_password}
                                  </Link>
                                </div>
                                <div style={{margin:'5px 25px',textDecoration: "none",cursor:'pointer'}}
                                 className={
                                  helpshow
                                    ? "sign-in-sec needhelpshow btn-text"
                                    : "sign-in-sec needhelpclose btn-text"
                                }
                                onClick={handleFirstTimeUser}>
                                   {langData?.lbl_login_fisrt_time_user}
                                </div>
                                {
                                  otherHelpLink &&
                                <div style={{ margin: "5px 25px" }}>
                                  <Link
                                    to={{pathname: otherHelpLink}}
                                    target='_blank'
                                    className={
                                      helpshow
                                        ? "sign-in-sec needhelpshow btn-text other-issues"
                                        : "sign-in-sec needhelpclose btn-text other-issues"
                                    }
                                    style={{
                                      color: forgotPassword_Color,
                                      textDecoration: "none",
                                    }}
                                  >
                                    {langData.lbl_login_other_issues}
                                  </Link>
                                </div>
                                }
                              </div>
                            </div>
                            : 
                            <div className="form-group d-flex justify-content-between mt-2" >
                                <div style={{ marginLeft: "3%" }}>
                                  <Link
                                    to={`/forgotpassword/${appId}?languageCode=${
                                      LanguageCode ? LanguageCode : "en"
                                    }`}
                                    className="sign-in-sec needhelpshow btn-text"
                                    style={{
                                      color: forgotPassword_Color,
                                      textDecoration: "none",
                                    }}
                                    aria-label={langData?.lbl_login_forgot_password}
                                  >
                                    {langData?.lbl_login_forgot_password}
                                  </Link>
                                </div>
                                {
                                  otherHelpLink &&
                                <div style={{ marginRight: "3%" }}>
                                  <Link
                                    to={{pathname: otherHelpLink}}
                                    target='_blank'
                                    className="sign-in-sec needhelpshow btn-text other-issues"
                                    style={{
                                      color: forgotPassword_Color,
                                      textDecoration: "none",
                                    }}
                                    aria-label={langData?.lbl_login_other_issues}
                                  >
                                    {langData?.lbl_login_other_issues}
                                  </Link>
                                </div>}
                            </div>
                          }
                          </div>
                          <div className="form-group">
                            <div className="or" aria-label={langData?.lbl_login_or}>
                              <span>{langData?.lbl_login_or}</span>
                            </div>
                          </div>
                          
                          <div className="form-group text-center mb-0">
                          {
                              data4.oktaConfigDetails?.enableSocialLogin &&
                              (
                               <>
                               {
                                data4.oktaConfigDetails?.googleIDP &&
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    style={{ background: SignIn_button_color }}
                                    id='google-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('google')}
                                    aria-label={langData?.lbl_login_google_sign_in}
                                  >
                                  {langData?.lbl_login_google_sign_in}
                                  </button>
                               }
                               {
                                data4.oktaConfigDetails?.facebookIDP &&
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    id='facebook-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('facebook')}
                                    aria-label="Login Via Facebook"
                                  >
                                  Login Via Facebook
                                  </button>
                               }
                               {
                                data4.oktaConfigDetails?.appleIDP &&
                                  <button
                                    className="btn btn-yellow btn-block mb-1"
                                    style={{ background: SignIn_button_color }}
                                    id='apple-connect'
                                    type="button"
                                    onClick={() => RouteGoogle('apple')}
                                    aria-label='Login Via Apple'
                                  >
                                    Login Via Apple
                                  </button>
                               }
                               </> 
                              
                              )
                              
                          }
                            <Link
                              to={`/create-account/${appId}?languageCode=${
                                LanguageCode ? LanguageCode : "en"
                              }&redirectUrl=${redirectUrl}`}
                              className="create-account btn-text mt-1"
                              style={{
                                textDecoration: "underline",
                                color: "black",
                              }}
                              aria-label={langData?.lbl_login_create_account}
                            >
                              {langData?.lbl_login_create_account}{" "}
                            </Link>
                            {/* <Link
                          // onClick={ () => {
                          //   window.top.location.href = `https://consumer.ite.secure.pepsico.com/create-account/${appId}?LanguageCode=${LanguageCode}`
                          // }}
                          className="create-account btn-text"
                            style={{
                              textDecoration: "underline",
                              color: "black",
                            }}
                          >
                          {langData.lbl_login_create_account}{" "}
                          </Link> */}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                  {StagedUser ? (
                    <div className="form-group" aria-label="Rohith">
                      {/* {
                        appBasicDetails?.enableGoogleCaptcha && appBasicDetails?.enableGoogleCaptcha ?
                        <ReCAPTCHA
                          sitekey={REACT_APP_CAPTCHA_SITE_KEY}
                          ref={captchaRef}
                          badge='inline'
                          className='form-group d-flex align-items-center justify-content-center'
                        /> :null
                      } */}
                      
                      <button
                        className="btn btn-yellow btn-block"
                        style={{
                          background: SignIn_button_backgroundColor,
                        }}
                        type="button"
                        onClick={GoogleVerify_ReactivateAccount}
                        disabled={Timer}
                        aria-label={langData?.lbl_login_reactivate_account}
                      >
                        {langData?.lbl_login_reactivate_account}
                      </button>
                      
                      <p className="before-sign-in">
                        <button
                          style={{
                            textDecoration: "underline",
                            border: "none",
                            background: "#fff",
                            color: "skyblue",
                            fontWeight: "bolder",
                            textAlign: "right",
                            marginTop: "1%",
                          }}
                          onClick={BackTOSignIn}
                          aria-label={langData?.lbl_login_back_to_signin}
                        >
                          {langData?.lbl_login_back_to_signin}
                        </button>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Form1;
