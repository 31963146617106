import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { Provider } from 'react-redux'
import store from "./Redux/store"
import interceptor from "./Components/Interceptor/interceptor"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interceptor.interceptor(store)

const {REACT_APP_CAPTCHA_SITE_KEY} = process.env

ReactDOM.render(
	 <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA_SITE_KEY} useEnterprise>
			<Provider store={store}>
					<App />
			</Provider>
	 </GoogleReCaptchaProvider>
	,
	document.getElementById("root")
)
