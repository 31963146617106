import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import "../../global1.css";
import DefaultLogo from "../../assests/images/DefaultLogo.png";
import DefaultBack from "../../assests/images/background-img.png";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { Backdrop,IconButton } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
// import { fetchLanguageData } from "../../Redux/Actions/fetch_language";
import DOMPurify from "dompurify";
import LanguageChange from "../LanguageChange/LanguageChange";

import {Visibility, VisibilityOff} from '@mui/icons-material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import sessionRoute from "../SessionRoute/sessionRoute";
//**** LOADER */

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: "100%",
    },
  })
);

  /******  CHANGING FAVICON DYNAMICALLY  */

  function getFaviconEl() {
    return document.getElementById('favicon');
  }
  function getAppTitle() {
    return document.getElementById('AppTitle');
  }

function Reset() {

  //**** INITIALIZE VARIABLES */
  const { REACT_APP_API_ENDPOINT } = process.env;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [intialLoader, setIntialLoader] = useState(false);
  const [data4, setData4] = useState({});
  const [redirectUrl, setredirectUrl] = useState("");
  const [createRedirect, setCreateRedirect] = useState('')
  
  const [userData,setuserData] = useState("")
  const[userNotFound, setuserNotFound] = useState(false)
  // PASSWORD COMPLEXITY
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [passwordComplexity, setPaswordComplexity] = useState({});
  // const dispatch = useDispatch();

  const Data = useParams()
  const id = DOMPurify.sanitize(Data.id)
  const  recoveryToken  = DOMPurify.sanitize(Data.recoveryToken);

  const { executeRecaptcha } = useGoogleReCaptcha()

  //*** Getting Query params Data */ 


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const LanguageCode = DOMPurify.sanitize(query.get("languageCode"));
  // const Email = DOMPurify.sanitize(query.get("email"))
  const userId = DOMPurify.sanitize(query.get("userId"))

  //***** REDIRECT URL   ******** */ 


  const redirect_location = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-config/${id}`)
      .then((res) => {
        setredirectUrl(res.data.resetRedirectURL !== "" ? res.data.resetRedirectURL : res.data.signInRedirectURL);
        setCreateRedirect(res.data.createRedirectURL !== "" ? res.data.createRedirectURL : res.data.appUrl)
        
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    redirect_location();
    //eslint-disable-next-line
  }, []);


  //***** RESET PASSWORD API */

  const Resetpassword = async (password, repassword, reCaptchaToken) => {
    setLoader(true);
    await axios
      .post(`${REACT_APP_API_ENDPOINT}reset-password/${recoveryToken}/${id}`, {
        password: password,
        cpassword: repassword,
        reCaptchaToken: reCaptchaToken
      },{
        headers: {
          "lang" : LanguageCode
        }
      })
      .then((res) => {
        setLoader(false);
        if (res.data.responseStatus === "SUCCESS") {
          toast.success(langData.lbl_reset_password_success, {
            position: "top-center",
          });
          if( id && LanguageCode){
              window.location.href = redirectUrl +  (redirectUrl.includes('?') ? `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` : `?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}`); 
          }
        } else {
          if(res.data.errorCode === 'invalid-captcha') {
            toast.error(res.data.messgae, {
              position: "top-center",
            });
          }
          const ErrorData = JSON.parse(res.data.messgae);
          if (ErrorData.errorCauses.length > 0) {
            toast.error(ErrorData.errorCauses[0].errorSummary, {
              position: "top-center",
            });
          }else {
            toast.error(res.data.messgae, {
              position: "top-center",
            });
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(langData.lbl_reset_password_correct_details, {
          position: "top-center",
        });
      });
  };

  //******* FETCHING DATA FROM RENDERING SERVICE */

  async function data2() {
    setIntialLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}rendering-service/${id}`)
      .then((res) => {
        setData4(res.data);
        setIntialLoader(false);
        passwordPolicies(res.data.appBasicDetails?.oktaGroupID)
        if(res.data?.appBasicDetails?.enableGoogleCaptcha){
          captchaVisibility()
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);



   //***********  GETTING DYNAMIC USER DATA FROM OKTA */

   async function User_details() {
    let reCaptcha_token =''
    reCaptcha_token = await executeRecaptcha("");
    setLoader(true);
    await axios
      .get(`${REACT_APP_API_ENDPOINT}get-user-detail/${userId}/${id}?reCaptchaToken=${reCaptcha_token}`)
      .then((res) => {
        setLoader(false);
        // let Obj = {}
        // Object.keys(res.data.profile).forEach((key) => {
        //   Obj={...Obj,[key]:res.data.profile[key]}
        // }
        // );
        if(res.data.responseStatus === "FAILURE"){
          setLoader(false);
           toast.error(res.data.messgae,{
            position:'top-center'
           })
           setuserNotFound(true)
        }else{
          setLoader(false);
          let Obj = {};
          Object.keys(res.data.profile).forEach((key) => {
            Obj = { ...Obj, [key]: res.data.profile[key] };
          });
          setuserData(Obj);
        }
        setuserData(res.data.profile);
      }).catch( err => {
        setLoader(false)
        if(err?.response?.data?.errorCode === "invalid-captcha"){
          toast.error('Invalid Captcha',{position:'top-center'});
        }
      });
  }

  useEffect(() => {
    User_details()
    //eslint-disable-next-line
  },[])


  //****** ASSIGNING RENDERING SERVICE DATA TO VARIABLES */
  const Header_Color = data4.custom_properties?.headerColor 
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;
  const AppTitle = data4.appBasicDetails?.appName;


  //******  GETTING LANGUAGE DATA FROM REDUX */

  const PageName = "lbl_reset_password";
  let langData;
  const LangData = useSelector((state) => state);
  const CodeLang = LangData.LanguageData.code;
  const pageName = LangData.LanguageData.pageName;
  const defaultLang = LangData.LanguageData.LanguageData[0].code;
  if (defaultLang === "default" && LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName).length === 0) {
    langData = LangData.LanguageData.LanguageData.filter((item) => item.code === "default")[0]?.data;
  } else {
    langData =LangData.LanguageData.LanguageData.length > 0 ? LangData.LanguageData.LanguageData.filter((item) => item.code === CodeLang && item.pageName === pageName)[0]?.data: {};
  }

  // useEffect(() => {
  //   dispatch(fetchLanguageData(LanguageCode, id, PageName));
  //   //eslint-disable-next-line
  // }, []);


  //*** FORMIK VALIDATION */

  const initialValues = {
    NewPassword: "",
    RePassword: "",
  };
  

  const validationSchema = yup.object({
    NewPassword: yup
      .string()
      .required(langData.lbl_reset_password_password_required)
      .min(
        passwordComplexity.minLength,
        langData['lbl_reset_password_min_password_length']?.replace("{0}", passwordComplexity.minLength)
      )
      .test("minNumber",langData.lbl_reset_password_one_number_required, (value) => {
        if (value && passwordComplexity.minNumber > 0) {
          const regex = /\d/g;
          let number = value.match(regex);
          if (number && number.length >= passwordComplexity.minNumber) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minLowercase", langData.lbl_reset_password_one_lowercase_required, (value) => {
        if (value && passwordComplexity.minLowerCase > 0) {
          const regex = /[a-z]/g;
          let text = value.match(regex);
          if (text && text.length >= passwordComplexity.minLowerCase) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minUppercase", langData.lbl_reset_password_one_uppercase_required, (value) => {
        if (value && passwordComplexity.minUpperCase > 0) {
          let text = value.match(/[A-Z]/g);
          if (text && text.length >= passwordComplexity.minUpperCase) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .test("minSymbol", langData.lbl_reset_password_one_symbol_required, (value) => {
        if (value && passwordComplexity.minSymbol > 0) {
          let text = value?.match(/[^A-Z0-9a-z]/g);
          if (text && text.length >= passwordComplexity.minSymbol) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }).test("no_Username", langData?.lbl_reset_password_no_user_name, (value) => {
        let Email = userData?.email;
          if(value && passwordComplexity.excludeUsername){
            if(Email !== ""){
              // const delimiters = ['.', ',', '-', '_', '#', '@'];
              const loginParts = Email?.split(/[@.\-_#,]/).filter(part => part.length >=3);
              const lowercaseLoginParts = loginParts?.map(part => part.toLowerCase());
              const passwordLowerCase = value.toLowerCase();
              let userName_Flag = true
                  for (const loginPart of lowercaseLoginParts) {
                    if(passwordLowerCase.includes(loginPart)){
                       userName_Flag = false;
                       break;
                    }
                  }
                  return userName_Flag
            }else{
              return true
            }
          }
        return true
      }).test( "no_Firstname", langData?.lbl_reset_password_no_first_name, (value) => {
        let Firstname = userData?.firstName
         if(value && passwordComplexity?.excludeAttributes?.length > 0 && passwordComplexity.excludeAttributes.indexOf("firstName") !== -1){
           if(userData?.firstName !== ""){
              if(userData?.firstName.length >=3 && value?.toLowerCase()?.includes(Firstname?.toLowerCase())){
                return false
              }
              else{
                return true
              }
           }else{
             return true
           } 
         }
         return true
      }).test( "no_Lastname", langData?.lbl_reset_password_no_last_name, (value) => {
        if(value && passwordComplexity?.excludeAttributes?.length > 0 && passwordComplexity.excludeAttributes.indexOf("lastName") !== -1){
          if(userData?.lastName !== ""){
             if(userData?.lastName.length >=3 && value?.toLowerCase()?.includes(userData?.lastName?.toLowerCase())){
               return false
             }else{
               
               return true
             }
          }else{
            return true
          } 
        }
        return true
     }).test("SpaceExclude", "do not include spaces in Password", (val) => {
      if((id === '163142638239744' || id === '915041708670976' || id === "824955038072832") && val){
        
        let regex = /^[^\s]+$/g;
        let Data = val.match(regex);
        if (Data) {
          return true;
        } else {
          return false; 
        }
      }else{
        return true;
      }
    }
    ),
    RePassword: yup
      .string()
      .required(langData.lbl_reset_password_cpassword_required)
      .oneOf(
        [yup.ref("NewPassword"), null],
        langData.lbl_reset_password_password_cpassword_not_match
      ),
  });

  //*** SUBMIT RESET PASSWORD API */
  const onSubmit = async (values, props) => {
    let reCaptcha_token = ""
    if(data4.appBasicDetails?.enableGoogleCaptcha){
      if (!executeRecaptcha) {
       toast.error(langData?.lbl_reset_password_captcha_not_available, {
         position: 'top-center'
       })
       return;
     }
     reCaptcha_token = await executeRecaptcha("");
   }else{
    reCaptcha_token = ""
   }
    Resetpassword(values.NewPassword, values.RePassword, reCaptcha_token);
    setTimeout(() => {
      props.resetForm();
    }, 1000);
  };

  //**** GETTING PASSWORD POLICIES  */

  const passwordPolicies = async (GroupId) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}policies/get-password-policies`)
      .then((res) => {
        const policyObject = res.data.filter((item) => {
          const groups = item.conditions.people.groups.include;
          if (
            groups.filter((groupId) => groupId === GroupId).length > 0
          ) {
            return true;
          }
          return false;
        });
        if (policyObject.length > 0) {
          setPaswordComplexity(policyObject[0]?.settings?.password?.complexity);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //*** BACK TO HOME PAGE */

  const redirect_sigin = () => {
    if( id && LanguageCode){
    window.location.href = redirectUrl +  (redirectUrl.includes('?') ? `&languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}` : `?languageCode=${LanguageCode ? LanguageCode : 'en'}&AppId=${id}`);
  }
  };

  //*********    FAVICON DYNAMIC CHANGE */

 const handlefavicon = () => {
  const favicon = getFaviconEl(); // Accessing favicon element
  if (logo) {
    favicon.href = logo;
  }
};
const handleAppTitle = () => {
  const Title = getAppTitle();
  if (AppTitle) {
    Title.innerText = AppTitle;
  }
};

useEffect(() => {
  handlefavicon();
  handleAppTitle();
  //eslint-disable-next-line
}, [logo]);


function captchaVisibility(){
  document.getElementsByClassName("grecaptcha-badge")[0].style.visibility = 'visible';
}

  
  return (
    <div>
      {intialLoader ? (
        <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Helmet>
            <link type="text/css" rel="stylesheet" href={cssFileLink} />
          </Helmet>
          <div className="body" style={{ fontFamily: fontFamily }}>
          <LanguageChange ConsumerAppId={id} PageName={PageName} Lang={LanguageCode ? LanguageCode : 'en'}/>
            <div>
              {loader ? (
                <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : null}
            </div>
            <div
              className="main-bg reset-password-sec"
              style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
              <div className="white-wrapper">
                <div className="text-center">
                  <div className="snacks-logo">
                    <img src={logo ? logo : DefaultLogo} alt="Logo" onClick={() => sessionRoute(data4?.appBasicDetails?.appRedirectionURL)}/>
                  </div>
                  <h2
                    className="snacks-title"
                    style={{ fontFamily: fontFamily, color: Header_Color }}
                  >
                    {langData.lbl_reset_password}
                  </h2>
                </div>
                <div className="wrapper-inner">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(props) => (
                      <Form className="mb-0">
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.NewPassword &&
                                props.errors.NewPassword
                                  ? "reset-password-sec form-group has-danger"
                                  : "reset-password-sec form-group"
                              }
                            >
                              <input
                                className="custom-input"
                                type={ showPassword ? "text" : "password"}
                                name="NewPassword"
                                id="NewPassword"
                                value={props.values.NewPassword}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{color: Input_color}}
                                required
                                aria-label="NewPassword"
                                disabled={userNotFound}
                              />
                              <label className="custom-label" htmlFor="NewPassword">
                                {langData.lbl_reset_password_new_password + "*"}
                              </label>
                              <IconButton
                                  className="reset-password-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowPassword( previousState => !previousState)
                                  }}
                                  aria-label="Show/hide password Icon"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                              {props.touched.NewPassword &&
                              props.errors.NewPassword ? (
                                <div className="reset-password-sec error-msg">
                                  {props.errors.NewPassword}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                        <Field>
                          {() => (
                            <div
                              className={
                                props.touched.RePassword &&
                                props.errors.RePassword
                                  ? "reset-password-sec form-group has-danger"
                                  : "reset-password-sec form-group"
                              }
                            >
                              <input
                                className="custom-input"
                                type={ showPassword1 ? "text" : "password"}
                                name="RePassword"
                                id="RePassword"
                                value={props.values.RePassword}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{color: Input_color}}
                                required
                                aria-label="RePassword"
                                disabled={userNotFound}
                              />
                              <label className="custom-label" htmlFor="RePassword">
                                {langData.lbl_reset_password_re_password + "*"}
                              </label>
                              <IconButton
                                  className="reset-password-sec eyeIcon"
                                  onClick={ () =>{
                                      setShowPassword1( previousState => !previousState)
                                  }}
                                  aria-label="Show/hide password Icon"
                                  >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                              {props.touched.RePassword &&
                              props.errors.RePassword ? (
                                <div className="reset-password-sec error-msg">
                                  {props.errors.RePassword}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                        <div className="form-group">
                          <button 
                          className="btn btn-yellow btn-block" 
                          style={{background: SignIn_button_backgroundColor, color: SignIn_button_color}}
                          disabled={userNotFound}
                          >
                            {langData.lbl_reset_password}
                          </button>
                        </div>
                        <div className="form-group d-flex justify-content-end">
                          <span className="back-sign-in">
                            {langData.lbl_reset_password_back_sign_in}
                          </span>
                          <button
                            type="button"
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bold",
                              color: "#000000",
                              fontSize: "16px",
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            aria-label={langData.lbl_reset_password_click}
                            onClick={redirect_sigin}
                          >
                            {langData.lbl_reset_password_click}
                          </button>
                        </div>
                        <div className="form-group">
                          <div className="or">
                            <span>{langData.lbl_reset_password_or}</span>
                          </div>
                        </div>
                        <div className="form-group text-center mb-0">
                          <Link
                            to={`/create-account/${id}?languageCode=${LanguageCode ? LanguageCode : 'en'}&redirectUrl=${createRedirect}`}
                            className="create-account btn-text"
                          >
                            {langData.lbl_reset_password_create_account}
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Reset;
