import React from 'react'
import "./home.css"

function Home() {
    return (
        <div className="home-page">
            Welcome to the Home page
        </div>
    )
}

export default Home
